import React, { useState } from 'react';

import { t } from 'i18next';

import CardModal from '../../components/CardModal';
import CostSpan from '../../components/CostSpan';
import ElementSpan from '../../components/ElementSpan';
import { LANGUAGES } from '../../constants/constants';
import i18n from '../../i18n';
import { Assets, CardType } from '../../types/card';

interface HorizontalCardDisplayProperties {
  card: CardType;
  quantity: number;
  isLast?: boolean;
  isCreater?: boolean;
}

const getCardUrl = (cardAssets: Assets, reference: string) => {
  const referenceBase = reference?.replace(/_[^_]*$/, '');
  const regex = new RegExp(`${referenceBase}_[A-Z]`, 'i');

  const matchingUrl = cardAssets?.WEB?.find((url: string) => regex.test(url));
  return matchingUrl || cardAssets?.WEB?.[0];
};

const HorizontalCardDisplay: React.FC<HorizontalCardDisplayProperties> = ({
  card,
  quantity,
  isLast,
  isCreater,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const fullArt = card.assets?.WEB && getCardUrl(card.assets, card?.reference);
  const cardImage = card.imagePath[currentLanguage];
  const elements = card.elements;

  const closeModal = () => setModalIsOpen(false);

  return (
    <>
      <div
        className={`flex items-center justify-between ${isLast ? 'rounded-b-md' : 'border-b-2 border-darkBlue'} ${!isCreater && 'rounded-md'} shadow-md cursor-pointer relative h-20 bg-cover bg-no-repeat text-white`}
        onClick={() => setModalIsOpen(true)}
        style={{
          backgroundImage: `url(${fullArt || cardImage})`,
          backgroundPositionY: `${fullArt ? '35%' : '35%'}`,
        }}
      >
        <div
          className={`flex items-center h-full p-2 md:px-4 w-full space-x-2 md:space-x-4 ${isLast && 'rounded-b-md'} ${!isCreater && 'rounded-md'} bg-darkBlue50`}
        >
          {elements?.MAIN_COST && elements?.RECALL_COST && (
            <CostSpan
              mainCost={elements.MAIN_COST}
              recallCost={elements.RECALL_COST}
            />
          )}
          <div className="flex flex-col ml-4 overflow-hidden w-3/5">
            <span className="text-base md:text-lg font-chillaxBold whitespace-nowrap overflow-hidden text-ellipsis">
              {card.name[currentLanguage]}
            </span>
            <span className="text-sm font-chillaxMedium uppercase">
              {t(`gameElements.${card.type}`)}
            </span>
          </div>
        </div>
        <div className="absolute flex justify-center -top-1 w-full h-6">
          {card.rarity && (
            <img
              src={`/assets/rarity/${card.rarity}.png`}
              alt={`${card.rarity}-icon`}
              className="h-4 object-cover"
            />
          )}
        </div>
        <div className="absolute top-0 right-1 md:right-4 flex flex-col justify-between items-end h-full p-1">
          <div
            className={`${quantity > (card.inMyCollection || 0) ? 'text-red-200' : 'text-green-200'} bg-darkBlue w-fit rounded-full px-2 py-1 text-xs font-chillaxBold`}
          >
            {quantity}/{card.inMyCollection || 0}
          </div>
          <div className="flex space-x-2">
            {elements?.FOREST_POWER && (
              <ElementSpan number={elements.FOREST_POWER} color="bg-forest" />
            )}
            {elements?.MOUNTAIN_POWER && (
              <ElementSpan
                number={elements.MOUNTAIN_POWER}
                color="bg-mountain"
              />
            )}
            {elements?.OCEAN_POWER && (
              <ElementSpan number={elements.OCEAN_POWER} color="bg-ocean" />
            )}
          </div>
        </div>
      </div>
      <div className="absolute z-50">
        <CardModal
          card={card}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        />
      </div>
    </>
  );
};

export default HorizontalCardDisplay;
