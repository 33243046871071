import { Trans } from 'react-i18next';

import { linkTo } from '../utils/linkTo';

const DiscordTuto = () => {
  return (
    <div className="flex justify-center items-center font-chillaxMedium bottom-0 px-2 md:px-8 w-full text-center">
      <span>
        <Trans
          i18nKey="navigation.tutoDiscord"
          components={{
            LinkTo: linkTo({
              dest: 'https://discord.gg/8yvbc3bk8h',
            }),
            Bold: <strong />,
          }}
        />
      </span>
    </div>
  );
};

export default DiscordTuto;
