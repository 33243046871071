import React from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import Button from './Button';
import DynamicSortButtons from './DynamicSortButtons';
import Filter from './Filter';
import Separator from './Separator';
import {
  PRIMARY_SORT_MODE,
  SECONDARY_SORT_MODE,
  SORT_DIRECTION,
  VERSION,
} from '../constants/constants';
import {
  cardsFactionData,
  cardsRarityData,
  cardsTypeData,
  cardsTypeDeckData,
  collectionFilterModes,
} from '../data/filtersData';

interface FilterModalProperties {
  modalIsOpen: boolean;
  closeModal: () => void;
  showCollectionFilter: boolean;
  setCardTypes: (array: string[]) => void;
  setCardRarities: (array: string[]) => void;
  setFactionFilters: (array: string[]) => void;
  setCollectionFilterValue: (number: number) => void;
  setCollectionFilterMode: (source: string) => void;
  factionFilters: string[];
  cardTypes: string[];
  cardRarities: string[];
  collectionFilterMode: string;
  collectionFilterValue: number;
  primarySortMode: string;
  setPrimarySortMode: (mode: string) => void;
  version: string;
  faction?: string;
  setVersion: (version: string) => void;
  secondarySortMode: string;
  setSecondarySortMode: (mode: string) => void;
  secondarySortDirection: string;
  setSecondarySortDirection: (direction: string) => void;
}

const FilterModal: React.FC<FilterModalProperties> = ({
  modalIsOpen,
  closeModal,
  showCollectionFilter,
  setCardTypes,
  setCardRarities,
  setFactionFilters,
  setCollectionFilterValue,
  setCollectionFilterMode,
  factionFilters,
  cardTypes,
  cardRarities,
  collectionFilterMode,
  collectionFilterValue,
  primarySortMode,
  setPrimarySortMode,
  version,
  faction,
  setVersion,
  secondarySortMode,
  setSecondarySortMode,
  secondarySortDirection,
  setSecondarySortDirection,
}) => {
  const { t } = useTranslation();

  const resetFilters = () => {
    setFactionFilters([]);
    setCardTypes([]);
    setCardRarities([]);
    setCollectionFilterMode('');
    setCollectionFilterValue(0);
  };

  const toggleSecondarySortDirection = (direction?: string) => {
    setSecondarySortDirection(
      direction ||
        (secondarySortDirection === SORT_DIRECTION.ASCENDING
          ? SORT_DIRECTION.DESCENDING
          : SORT_DIRECTION.ASCENDING)
    );
  };

  const versionOptions = [
    {
      mode: VERSION.ALL,
      label: 'filterTitle.all',
    },
    { mode: VERSION.KS, label: 'filterTitle.ks' },
    { mode: VERSION.DEFAULT, label: 'filterTitle.default' },
  ];

  const versionCollectionOptions = [
    {
      mode: VERSION.ALL,
      label: 'filterTitle.all',
    },
    { mode: VERSION.KS, label: 'filterTitle.ks' },
    { mode: VERSION.DEFAULT, label: 'filterTitle.default' },
  ];

  const primarySortOptions = [
    {
      mode: PRIMARY_SORT_MODE.BY_NOTHING,
      label: 'filterTitle.nothing',
    },
    { mode: PRIMARY_SORT_MODE.BY_TYPE, label: 'filterTitle.type' },
    { mode: PRIMARY_SORT_MODE.BY_FACTION, label: 'filterTitle.faction' },
  ];

  const secondarySortOptions = [
    {
      mode: SECONDARY_SORT_MODE.BY_NOTHING,
      label: 'filterTitle.nothing',
    },
    {
      mode: SECONDARY_SORT_MODE.BY_LATEST_ADDITION,
      label: 'filterTitle.latestAddition',
      showIcon: true,
      showCollection: true,
    },
    {
      mode: SECONDARY_SORT_MODE.BY_MAIN_COST,
      label: 'filterTitle.mana',
      showIcon: true,
    },
    {
      mode: SECONDARY_SORT_MODE.BY_NAME,
      label: 'filterTitle.name',
      showIcon: true,
    },
    {
      mode: SECONDARY_SORT_MODE.BY_NUMBER,
      label: 'filterTitle.number',
      showIcon: true,
    },
  ];

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <div
        className={
          'p-4 rounded shadow-lg max-w-2xl w-full relative font-chillaxMedium space-y-4 border-4 border-darkBlue bg-beige'
        }
      >
        <button
          onClick={closeModal}
          className="absolute text-2xl font-bold top-3 right-3 text-darkBlue hover:text-gray-800 cursor-pointer"
        >
          ✕
        </button>
        <div className={'grid grid-cols-2 md:grid-cols-3 gap-4'}>
          {!faction && (
            <Filter
              id="factionFilter"
              label={t('filterTitle.faction')}
              value={factionFilters}
              onChange={(value) => setFactionFilters(value as string[])}
              options={cardsFactionData}
              multiSelect
            />
          )}
          <Filter
            id="cardType"
            label={t('filterTitle.type')}
            value={cardTypes}
            onChange={(value) => setCardTypes(value as string[])}
            options={faction ? cardsTypeDeckData : cardsTypeData}
            multiSelect
          />
          <Filter
            id="cardRarity"
            label={t('filterTitle.rarity')}
            value={cardRarities}
            onChange={(value) => setCardRarities(value as string[])}
            options={cardsRarityData}
            multiSelect
          />
          {showCollectionFilter && (
            <div className="flex flex-col">
              <label className="block text-sm font-chillaxBold text-darkBlue mb-2">
                {t('filterTitle.inMyCollection')}
              </label>
              <div className="flex space-x-2">
                <div className="relative">
                  <select
                    value={collectionFilterMode}
                    onChange={(error) =>
                      setCollectionFilterMode(error.target.value)
                    }
                    className="block appearance-none w-full bg-lightBlue border-2 border-darkBlue px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                  >
                    {collectionFilterModes.map((mode) => (
                      <option key={mode.value} value={mode.value}>
                        {mode.label}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-darkBlue">
                    <svg
                      className="fill-current h-4 w-fit"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M7 10l5 5 5-5H7z" />
                    </svg>
                  </div>
                </div>
                <input
                  type="number"
                  min={0}
                  value={collectionFilterValue}
                  onChange={(error) =>
                    setCollectionFilterValue(Number(error.target.value))
                  }
                  className="h-full p-2 w-16  block shadow sm:text-sm rounded border-2 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                />
              </div>
            </div>
          )}
        </div>
        <Separator />
        <label className="block text-sm font-chillaxBold text-gray-700">
          {t('filterTitle.sortMode')}
        </label>
        <DynamicSortButtons
          sortMode={version}
          setSortMode={setVersion}
          options={
            showCollectionFilter ? versionCollectionOptions : versionOptions
          }
        />
        <DynamicSortButtons
          sortMode={primarySortMode}
          setSortMode={setPrimarySortMode}
          options={primarySortOptions}
        />
        <DynamicSortButtons
          sortMode={secondarySortMode}
          sortDirection={secondarySortDirection}
          setSortMode={setSecondarySortMode}
          toggleSortDirection={toggleSecondarySortDirection}
          options={secondarySortOptions}
          showCollectionFilter={showCollectionFilter}
        />
        <Separator />
        <Button onClick={closeModal} full>
          {t('actions.validate')}
        </Button>
        <Button onClick={resetFilters} full>
          {t('actions.reset')}
        </Button>
      </div>
    </Modal>
  );
};

export default FilterModal;
