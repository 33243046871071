import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import DeckValidationBanner from '../components/DeckValidationBanner';
import Separator from '../components/Separator';
import SeparatorVertical from '../components/SeparatorVertical';
import { LANGUAGES } from '../constants/constants';
import { TABS_DECKS_VIEW } from '../constants/tabs';
import { exportOptions, exportToolsDeckOptions } from '../data/exportData';
import useDeckBuilder from '../hooks/useDeckBuilder';
import useMyCards from '../hooks/useMyCards';
import i18n from '../i18n';
import BackButton from '../parts/BackButton';
import DeckCardList from '../parts/decks/DeckCardList';
import DeckHorizontalCardList from '../parts/decks/DeckHorizontalCardList';
import DeckSidePanel from '../parts/decks/DeckSidePanel';
import DeckSummary from '../parts/decks/DeckSummary';
import ExportCards from '../parts/ExportCards';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import CardSelectionInputModal from '../parts/modals/CardSelectionInputModal';
import ChangeRarityModal from '../parts/modals/ChangeRarityModal';
import ModifyDeckModal from '../parts/modals/ModifyDeckModal';
import SelectRarityModal from '../parts/modals/SelectRarityModal';
import Tabs from '../parts/Tabs';
import { createTextFromCards } from '../utils/formatCards';

const DeckBuilder = () => {
  const {
    isDeckLoaded,
    isCreater,
    deck,
    baseDeckCards,
    deckCards,
    selectedAction,
    selectedParticuleID,
    allCardsWithUniques,
    errorMessage,
    cardsModalIsOpen,
    rarityModalIsOpen,
    changeRarityModalIsOpen,
    setBaseDeckCards,
    handleCloseModal,
    setChangeRarityModalIsOpen,
    handleSaveDeck,
    setRarityModalIsOpen,
    addDeckParticleId,
    removeDeckParticleId,
    changeCardRarity,
    setCardsModalIsOpen,
    updateDeckState,
    handleCardAction,
  } = useDeckBuilder();

  const { t } = useTranslation();
  const myCards = useMyCards(true);
  const [statsPanelIsOpen, setStatsPanelIsOpen] = useState(false);
  const [updateDeckModalIsOpen, setUpdateDeckModalIsOpen] = useState(false);
  const [selectedViewTab, setSelectedViewTab] = useState<string>(
    TABS_DECKS_VIEW.GRID.key
  );

  const handleToggleStatsPanel = () => setStatsPanelIsOpen((isOpen) => !isOpen);
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  if (!isDeckLoaded) {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.decks') + ' | Altered Card';

  return (
    <Layout noSidebar isLeftSidePanelOpen={statsPanelIsOpen}>
      <div className="space-y-2 md:space-y-6">
        <div className="md:hidden fixed left-0 top-1/2 z-10">
          <Button
            onClick={() => setCardsModalIsOpen(true)}
            full
            isVertical
            right
          >
            {t('actions.selectCards')}
          </Button>
        </div>
        <BackButton
          to="decks"
          title={`${deck?.hero?.name?.[currentLanguage]} / ${deck?.name} - ${deck?.isPublic ? t('deck.public') : t('deck.private')}`}
          action={
            isCreater
              ? {
                  label: 'actions.modify',
                  onClick: () => setUpdateDeckModalIsOpen(true),
                }
              : undefined
          }
        />
        <div className="hidden md:flex flex-row items-center justify-center h-28 w-full space-x-8">
          {isCreater && (
            <>
              <div className="flex flex-col space-y-2">
                <Button onClick={() => setCardsModalIsOpen(true)}>
                  {t('actions.selectCards')}
                </Button>
                <Button onClick={handleSaveDeck} full>
                  {t('actions.saveDeck')}
                </Button>
              </div>
              <SeparatorVertical />
            </>
          )}
          {baseDeckCards.length > 0 && <DeckSummary cards={baseDeckCards} />}
          <SeparatorVertical />
          <div className="flex flex-col space-y-2">
            <Button onClick={handleToggleStatsPanel}>
              {t('actions.advancedStats')}
            </Button>
            {deck && (
              <ExportCards
                cards={[deck.hero, ...baseDeckCards]}
                exportOptions={exportOptions}
                exportToolsOptions={exportToolsDeckOptions}
                full
              />
            )}
          </div>
        </div>
        <div className="md:hidden flex flex-col items-center justify-center w-full space-y-2 p-2">
          {isCreater && (
            <div className="h-fit w-full space-y-2">
              <div className="flex gap-2">
                <Button onClick={handleSaveDeck} full>
                  {t('actions.saveDeck')}
                </Button>

                {deck && (
                  <ExportCards
                    cards={[deck.hero, ...baseDeckCards]}
                    exportOptions={exportOptions}
                    exportToolsOptions={exportToolsDeckOptions}
                    full
                  />
                )}
              </div>
              <Separator />
            </div>
          )}
          {baseDeckCards.length > 0 && (
            <DeckSummary
              cards={baseDeckCards}
              handleToggleStatsPanel={handleToggleStatsPanel}
              small
            />
          )}
        </div>
        {errorMessage && (
          <div className="flex justify-center">
            <span className="text-red-600">{errorMessage}</span>
          </div>
        )}
        <Tabs
          tabs={TABS_DECKS_VIEW}
          selectedTab={selectedViewTab}
          setSelectedTab={setSelectedViewTab}
          bottom
        />
        <DeckValidationBanner
          allCards={allCardsWithUniques}
          baseDeckCards={baseDeckCards}
          myCards={myCards}
        />
        {selectedViewTab === TABS_DECKS_VIEW.GRID.key && (
          <DeckCardList
            allCards={allCardsWithUniques}
            cards={deckCards}
            isCreater={isCreater}
            addDeckParticulId={addDeckParticleId}
            removeDeckParticulId={removeDeckParticleId}
            changeRarity={changeCardRarity}
          />
        )}
        {selectedViewTab === TABS_DECKS_VIEW.LIST.key && (
          <DeckHorizontalCardList
            cards={deckCards}
            isCreater={isCreater}
            addDeckParticulId={addDeckParticleId}
            removeDeckParticulId={removeDeckParticleId}
            changeRarity={changeCardRarity}
          />
        )}
      </div>

      <DeckSidePanel
        cards={baseDeckCards}
        isOpen={statsPanelIsOpen}
        closeSidePanel={() => setStatsPanelIsOpen(false)}
      />
      {rarityModalIsOpen && deck && (
        <SelectRarityModal
          isOpen={rarityModalIsOpen}
          closeModal={() => setRarityModalIsOpen(false)}
          handleCardAction={handleCardAction}
          baseDeckCards={baseDeckCards}
          selectedParticuleID={selectedParticuleID}
          selectedAction={selectedAction}
          allCards={allCardsWithUniques}
          deck={deck}
        />
      )}
      {cardsModalIsOpen && (
        <CardSelectionInputModal
          isOpen={cardsModalIsOpen}
          onClose={handleCloseModal}
          cards={allCardsWithUniques}
          currentCards={createTextFromCards(baseDeckCards)}
          faction={deck?.faction}
          quantity
        />
      )}
      {updateDeckModalIsOpen && deck && (
        <ModifyDeckModal
          isOpen={updateDeckModalIsOpen}
          closeModal={() => setUpdateDeckModalIsOpen(false)}
          deck={deck}
          updateDeckState={updateDeckState}
        />
      )}
      {deck && changeRarityModalIsOpen && (
        <ChangeRarityModal
          isOpen={changeRarityModalIsOpen}
          closeModal={() => setChangeRarityModalIsOpen(false)}
          setBaseDeckCards={setBaseDeckCards}
          selectedParticuleID={selectedParticuleID}
          allCards={allCardsWithUniques}
          baseDeckCards={baseDeckCards}
          deck={deck}
        />
      )}
    </Layout>
  );
};

export default DeckBuilder;
