import { useMemo } from 'react';

import { PARTICULE_ID_REGEX } from '../constants/constants';
import { CardType } from '../types/card';
import { CardDeckType } from '../types/deck';

interface ValidationError {
  code: string;
  references?: { particuleId: string; quantity: number }[];
  nbCards?: number;
}

interface UseDeckValidationResult {
  isLegal: boolean;
  errors: ValidationError[];
}

const useDeckValidation = (
  baseDeckCards: CardDeckType[],
  myCards: CardType[]
): UseDeckValidationResult => {
  const { isLegal, errors } = useMemo(() => {
    const errors: ValidationError[] = [];

    // 1. 39 cartes minimum
    const totalCards = baseDeckCards.reduce(
      (sum, card) => sum + card.quantity,
      0
    );
    if (totalCards < 39) {
      errors.push({
        code: 'MINIMUM_CARDS',
      });
    }

    // 2. 15 rares maximum
    const totalRares = baseDeckCards
      .filter((card) => card.rarity === 'RARE')
      .reduce((sum, card) => sum + card.quantity, 0);
    if (totalRares > 15) {
      errors.push({
        code: 'MAX_RARES',
        nbCards: totalRares,
      });
    }

    // 3. 3 uniques maximum
    const totalUniques = baseDeckCards
      .filter((card) => card.rarity === 'UNIQUE')
      .reduce((sum, card) => sum + card.quantity, 0);
    if (totalUniques > 3) {
      errors.push({
        code: 'MAX_UNIQUES',
        nbCards: totalUniques,
      });
    }

    // 4. Maximum 3 cartes par particulId
    const particulIdMap: { [key: string]: number } = {};
    const particulIdRegex = PARTICULE_ID_REGEX;
    for (const card of baseDeckCards) {
      const match = card?.reference?.match(particulIdRegex);
      if (match) {
        const particulId = match[0];
        particulIdMap[particulId] =
          (particulIdMap[particulId] || 0) + card.quantity;
      }
    }
    const invalidParticulIds: { particuleId: string; quantity: number }[] = [];
    Object.entries(particulIdMap).map(([id, quantity]) => {
      if (quantity > 3)
        invalidParticulIds.push({ particuleId: id, quantity: quantity });
    });

    if (invalidParticulIds.length > 0) {
      errors.push({
        code: 'MAX_PARTICULID',
        references: invalidParticulIds,
      });
    }

    // 5. Possession des cartes communes
    const myCommonCardsMap: { [key: string]: number } = {};
    const commonCards = baseDeckCards.filter(
      (card) => card.rarity === 'COMMON'
    );
    for (const card of commonCards) {
      if (card.inMyCollection) {
        const normalizedReference = card?.reference.replace(
          '_COREKS_',
          '_CORE_'
        );
        myCommonCardsMap[normalizedReference] = card.inMyCollection;
      }
    }

    const insufficientCommonCards: { particuleId: string; quantity: number }[] =
      [];
    for (const deckCard of commonCards) {
      const normalizedReference = deckCard?.reference?.replace(
        '_COREKS_',
        '_CORE_'
      );
      const ownedQuantity = myCommonCardsMap[normalizedReference] || 0;
      if (ownedQuantity < deckCard.quantity) {
        insufficientCommonCards.push({
          particuleId: deckCard?.reference,
          quantity: deckCard.quantity - ownedQuantity,
        });
      }
    }
    if (insufficientCommonCards.length > 0) {
      errors.push({
        code: 'INSUFFICIENT_COMMON_CARDS',
        references: insufficientCommonCards,
      });
    }

    // 6. Possession des cartes rares
    const myRareCardsMap: { [key: string]: number } = {};
    const rareCards = baseDeckCards.filter((card) => card.rarity === 'RARE');
    for (const card of rareCards) {
      if (card.inMyCollection) {
        const normalizedReference = card?.reference.replace(
          '_COREKS_',
          '_CORE_'
        );
        myRareCardsMap[normalizedReference] = card.inMyCollection;
      }
    }

    const insufficientRareCards: { particuleId: string; quantity: number }[] =
      [];
    for (const deckCard of rareCards) {
      const normalizedReference = deckCard?.reference?.replace(
        '_COREKS_',
        '_CORE_'
      );
      const ownedQuantity = myRareCardsMap[normalizedReference] || 0;
      if (ownedQuantity < deckCard.quantity) {
        insufficientRareCards.push({
          particuleId: deckCard?.reference,
          quantity: deckCard.quantity - ownedQuantity,
        });
      }
    }
    if (insufficientRareCards.length > 0) {
      errors.push({
        code: 'INSUFFICIENT_RARE_CARDS',
        references: insufficientRareCards,
      });
    }

    return {
      isLegal: errors.length === 0,
      errors,
    };
  }, [baseDeckCards, myCards]);

  return { isLegal, errors };
};

export default useDeckValidation;
