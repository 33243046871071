import React from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import { Team } from '../../types/team';
import { User } from '../../types/user';

interface MembersModalProperties {
  isOpen: boolean;
  team: Team;
  closeModal: () => void;
}

const MembersModal: React.FC<MembersModalProperties> = ({
  isOpen,
  closeModal,
  team,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige p-10 rounded shadow-lg w-fit border-4 space-y-6 border-darkBlue text-darkBlue">
        <div className="space-y-2">
          <h2 className="text-xl font-chillaxBold">{t('teams.members')}</h2>
          <ul className="list-disc list-inside">
            {team?.members?.map((member: User) => {
              return (
                <li className="font-chillaxMedium" key={member._id}>
                  {team?.admin === member._id
                    ? `${member.pseudo} (admin)`
                    : member.pseudo}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="space-y-2">
          <div className="flex justify-start items-center space-x-4">
            <Button onClick={closeModal}>{t('actions.close')}</Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MembersModal;
