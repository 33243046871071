interface ElementSpanProperties {
  mainCost: string;
  recallCost: string;
}

const CostSpan = ({ mainCost, recallCost }: ElementSpanProperties) => {
  const isMainCostHighlighted =
    mainCost.startsWith('#') && mainCost.endsWith('#');
  const isRecallCostHighlighted =
    recallCost.startsWith('#') && recallCost.endsWith('#');

  const formattedMainCost = isMainCostHighlighted
    ? mainCost.slice(1, -1)
    : mainCost;
  const formattedRecallCost = isRecallCostHighlighted
    ? recallCost.slice(1, -1)
    : recallCost;

  return (
    <div className="relative w-10 h-9">
      <div
        className={`flex items-center justify-center rounded-full font-chillaxBold shadow-lg shadow-darkBlue50 w-6 h-6 bg-reserve absolute bottom-0 right-0 ${
          isRecallCostHighlighted ? 'text-yellowRare' : 'text-white'
        }`}
      >
        {formattedRecallCost}
      </div>
      <div
        className={`flex items-center justify-center rounded-full font-chillaxBold shadow-lg shadow-darkBlue50 w-6 h-6 bg-hand absolute top-0 left-0 ${
          isMainCostHighlighted ? 'text-yellowRare' : 'text-white'
        }`}
      >
        {formattedMainCost}
      </div>
    </div>
  );
};

export default CostSpan;
