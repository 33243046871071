import React from 'react';

import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from 'react-icons/fa';

import Button from '../components/Button';
import Separator from '../components/Separator';
import { LANGUAGES } from '../constants/constants';
import { getNavigateUrl } from '../utils/getNavigateUrl';

interface BackButtonProperties {
  to: string;
  title?: string;
  action?: { label: string; onClick: () => void };
}
const BackButton: React.FC<BackButtonProperties> = ({ to, title, action }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  return (
    <div className="space-y-1">
      <div className="flex justify-between">
        <div className="flex space-x-2 items-center md:items-end">
          <div className="flex flex-col max-w-32 space-y-2">
            <Button isLink to={getNavigateUrl(currentLanguage, to)}>
              <div className="flex justify-center items-center space-x-2">
                <FaArrowLeft />
                <span>{t('actions.return')}</span>
              </div>
            </Button>
            {action && (
              <div className="flex md:hidden items-center md:items-end">
                <Button onClick={action.onClick} full>
                  {t(action.label)}
                </Button>
              </div>
            )}
          </div>
          <span className="text-xl md:text-3xl font-chillaxBold">{` - `}</span>
          <h1 className="text-xl md:text-3xl font-chillaxBold">{title}</h1>
        </div>
        {action && (
          <div className="hidden md:flex items-center md:items-end">
            <Button onClick={action.onClick}>{t(action.label)}</Button>
          </div>
        )}
      </div>
      <Separator />
    </div>
  );
};

export default BackButton;
