import axios from 'axios';

import { fetchUniqueCards } from './fetchUniqueCards';
import { CardStatsAPI, CardStatsInfo, CardType } from '../types/card';
import { isUnique } from '../utils/cardUtilities';
import { handleError } from '../utils/errorHandler';
import { formatCards } from '../utils/formatCards';

interface FetchCollectionCardsResult {
  transformedCards: CardStatsInfo[];
  uniqueCards: CardType[];
}

export const fetchCollectionCards = async (
  token: string,
  setErrorMessage: (error: string) => void,
  onProgressUpdate: (message: string) => void
): Promise<FetchCollectionCardsResult | undefined> => {
  try {
    const itemsPerPage = 36;
    let currentPage = 1;
    let totalPages = 1;
    let loadedCardStats = 0;

    const allCardStats: CardStatsInfo[] = [];

    // Step 1: Fetch all card stats
    do {
      const statsResponse = await axios.get(
        'https://api.altered.gg/cards/stats',
        {
          params: {
            collection: true,
            page: currentPage,
            itemsPerPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept-Language': 'fr-fr',
          },
        }
      );

      const statsData: CardStatsAPI[] = statsResponse.data['hydra:member'];
      const filteredStats: CardStatsInfo[] = formatCards(statsData);

      allCardStats.push(...filteredStats);
      loadedCardStats += statsData.length;

      onProgressUpdate(
        ` ${loadedCardStats}/${statsResponse.data['hydra:totalItems']}`
      );

      const totalItems = statsResponse.data['hydra:totalItems'];
      totalPages = Math.ceil(totalItems / itemsPerPage);
      currentPage += 1;
    } while (currentPage <= totalPages);

    // Step 2: Separate stats into unique and normal cards
    const uniqueCardStats = allCardStats.filter((card) =>
      isUnique(card.reference)
    );
    const normalCardStats = allCardStats.filter(
      (card) => !isUnique(card.reference)
    );

    // Step 3: Fetch all unique cards using the new isolated function
    const uniqueCardsResult = await fetchUniqueCards(
      token,
      uniqueCardStats,
      setErrorMessage,
      onProgressUpdate
    );

    if (!uniqueCardsResult) {
      return undefined;
    }

    return {
      transformedCards: normalCardStats,
      uniqueCards: uniqueCardsResult.uniqueCards,
    };
  } catch (error) {
    console.error('Error fetching collection cards:', error);
    handleError(error, setErrorMessage);
    return undefined;
  }
};
