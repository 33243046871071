import { useEffect, useRef, useState } from 'react';

import { CardType } from '../types/card';

interface UseCardInputResult {
  cardInput: string;
  selectedCards: CardType[];
  handleCardInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  handleCardSelectionChange: (cards: CardType[]) => void;
  setSelectedCards: (cards: CardType[]) => void;
  setCardInput: (input: string) => void;
  errorMessage?: string;
}

export const useCardInputAdvanced = (
  allCards?: CardType[],
  defaultList = ''
): UseCardInputResult => {
  const [cardInput, setCardInput] = useState<string>(defaultList);
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Utilisation d'une référence pour éviter des boucles infinies de mise à jour
  const isUpdatingFromCardInput = useRef(false);
  const isUpdatingFromSelection = useRef(false);

  // Synchronise `selectedCards` en fonction de la valeur de `cardInput`
  useEffect(() => {
    if (!allCards) return;

    if (isUpdatingFromSelection.current) {
      isUpdatingFromSelection.current = false;
      return;
    }

    const cardEntries = cardInput.split('\n').filter(Boolean);
    const updatedSelectedCards: CardType[] = [];
    let hasError = false;

    for (const entry of cardEntries) {
      const [countString, id] = entry.split(' ');
      const count = Number.parseInt(countString, 10);
      const card = allCards.find((card) => card.reference === id);

      if (card && !Number.isNaN(count)) {
        updatedSelectedCards.push({ ...card, nbSelected: count });
      } else {
        hasError = true;
        setErrorMessage(`Invalid card reference or count: ${entry}`);
        break;
      }
    }

    if (!hasError) {
      isUpdatingFromCardInput.current = true;
      setSelectedCards(updatedSelectedCards);
      setErrorMessage('');
    }
  }, [cardInput, allCards]);

  // Synchronise `cardInput` en fonction de `selectedCards`
  useEffect(() => {
    if (isUpdatingFromCardInput.current) {
      isUpdatingFromCardInput.current = false;
      return;
    }

    const selectedCardIds = selectedCards
      .map((card) => `${card.nbSelected || 1} ${card.reference}`)
      .join('\n');

    if (selectedCardIds !== cardInput) {
      isUpdatingFromSelection.current = true;
      setCardInput(selectedCardIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCards]);

  // Permet de mettre à jour les cartes sélectionnées via une autre méthode
  const handleCardSelectionChange = (cards: CardType[]) => {
    setSelectedCards(cards);
  };

  const handleCardInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCardInput(event.target.value);
  };

  return {
    cardInput,
    selectedCards,
    handleCardInputChange,
    handleCardSelectionChange,
    setSelectedCards,
    setCardInput,
    errorMessage,
  };
};
