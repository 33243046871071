import React from 'react';

import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/dist/ReactToastify.css';
import '@mountainpass/react-pokemon-cards/dist/css/cards.css';

import App from './App';
import i18n from './i18n';
import store, { persistor } from './store/store';
import './css/index.css';
import './css/chillax.css';

const root = ReactDOM.createRoot(
  document.querySelector('#root') as HTMLElement
);
document.documentElement.lang = i18n.language;
i18n.on('languageChanged', (lng) => (document.documentElement.lang = lng));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={undefined} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <App />
          <ToastContainer position="bottom-right" />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
