import { PARTICULE_ID_REGEX } from '../constants/constants';
import { CardType } from '../types/card';
import { CardDeckType, DeckCardsType } from '../types/deck';

export const getTotalCardCount = (deck: DeckCardsType): number => {
  return deck.cards.reduce((total, card) => total + (card.quantity || 1), 0);
};

export function getParticleId(card: CardType) {
  const particleIdMatch = card.reference?.match(PARTICULE_ID_REGEX);
  const particleId = particleIdMatch ? particleIdMatch[0] : '';
  return particleId;
}

function addCardToDeck(
  cardMap: { [key: string]: CardDeckType[] },
  card: CardDeckType
) {
  const particleId = getParticleId(card);
  if (particleId) {
    if (cardMap[particleId]) {
      cardMap[particleId].push(card);
    } else {
      cardMap[particleId] = [card];
    }
  }
}

export function createDeckCards(cards: CardDeckType[]): DeckCardsType[] {
  const cardMap: { [key: string]: CardDeckType[] } = {};

  for (const card of cards) {
    addCardToDeck(cardMap, card);
  }

  const rarityOrder: { [key in 'COMMON' | 'RARE' | 'UNIQUE']: number } = {
    COMMON: 3,
    RARE: 2,
    UNIQUE: 1,
  };

  return Object.entries(cardMap).map(([particleId, cards]) => ({
    particleId,
    cards: cards.sort((a, b) => {
      const rarityA = rarityOrder[a.rarity as keyof typeof rarityOrder] || 0;
      const rarityB = rarityOrder[b.rarity as keyof typeof rarityOrder] || 0;
      return rarityA - rarityB;
    }),
  }));
}
