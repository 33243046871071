import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IoFilterSharp } from 'react-icons/io5';

import Button from '../components/Button';
import FriendFilterModal from '../components/FriendFilterModal';
import Separator from '../components/Separator';
import { FRIEND_SORT_MODE, SORT_DIRECTION } from '../constants/constants';
import { UserFriendship } from '../types/card';
import { sortFriends } from '../utils/friendUtilities';

interface FriendFiltersProperties {
  friends: UserFriendship[];
  filteredFriends: UserFriendship[];
  setFilteredFriends: (filtered: UserFriendship[]) => void;
}

const FriendFilters: React.FC<FriendFiltersProperties> = ({
  friends,
  filteredFriends,
  setFilteredFriends,
}) => {
  const { t } = useTranslation();
  const [sortMode, setSortMode] = useState<string>(
    FRIEND_SORT_MODE.BY_TRADE_CARDS
  );
  const [sortDirection, setSortDirection] = useState<string>(
    SORT_DIRECTION.ASCENDING
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    const sortedFriends = sortFriends(friends, sortMode, sortDirection);

    const isDifferent =
      JSON.stringify(sortedFriends) !== JSON.stringify(filteredFriends);

    if (isDifferent) {
      setFilteredFriends(sortedFriends);
    }
  }, [sortMode, sortDirection, friends, filteredFriends, setFilteredFriends]);

  return (
    <div className="w-full space-y-6 lg:space-y-10">
      <div className="relative">
        <div className="flex justify-between items-end">
          <span className="text-darkBlue hidden lg:block font-chillaxMedium whitespace-nowrap">
            {t('friendship.filter.onScreenFriends', {
              nb: filteredFriends.length,
            })}
          </span>
          <div className="flex justify-center items-center w-full h-full">
            <div className="flex w-fit rounded lg:absolute -bottom-3 right-0 justify-around">
              <div className="flex items-end">
                <Button onClick={openModal}>
                  <div className="flex justify-center items-center">
                    <IoFilterSharp className="mr-1" />
                    {t('actions.filters')}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Separator desktop />
      </div>
      <FriendFilterModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        sortMode={sortMode}
        setSortMode={setSortMode}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <div className="lg:hidden">
        <span className="text-darkBlue">
          {t('friendship.filter.onScreenFriends', {
            nb: filteredFriends.length,
          })}
        </span>
        <Separator />
      </div>
    </div>
  );
};

export default FriendFilters;
