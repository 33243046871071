import React from 'react';

interface LoadingProperties {
  label: string;
}

const Loading: React.FC<LoadingProperties> = ({ label }) => {
  return (
    <div className="flex w-full h-screen justify-center items-center">
      <p className="text-3xl font-chillaxBold uppercase text-center">{label}</p>
    </div>
  );
};

export default Loading;
