import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from './Button';
import CreateTeamModal from '../parts/modals/CreateTeamModal';
import JoinTeamModal from '../parts/modals/JoinTeamModal';

const TeamActions: React.FC = () => {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);

  return (
    <div className="w-full">
      <div className="flex space-x-2">
        <Button onClick={() => setShowCreateModal(true)}>
          {t('actions.createTeam')}
        </Button>
        <Button onClick={() => setShowJoinModal(true)}>
          {t('actions.joinTeam')}
        </Button>
      </div>

      {showCreateModal && (
        <CreateTeamModal
          isOpen={showCreateModal}
          closeModal={() => setShowCreateModal(false)}
        />
      )}
      {showJoinModal && (
        <JoinTeamModal
          isOpen={showJoinModal}
          closeModal={() => setShowJoinModal(false)}
        />
      )}
    </div>
  );
};

export default TeamActions;
