import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useCollection } from './useCollection';
import useFetchCards from './useFetchCards';
import { CARDS_PATH } from '../constants/constants';
import { setCollectionCards } from '../store/slices/collectionCardsSlice';
import { RootState } from '../store/store';
import { CardType } from '../types/card';
import { fusionSets } from '../utils/cardUtilities';

const useMyCards = (isFusion?: boolean) => {
  const dispatch = useDispatch();
  const { getCollection } = useCollection();
  const [fusionCards, setFusionCards] = useState<CardType[]>([]);

  const user = useSelector((state: RootState) => state.user);

  const myCards = useSelector(
    (state: RootState) => state.collectionCards.cards
  );

  const isAllFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const isFusionEnabled = isFusion ?? isAllFusionEnabled;

  const { allCards } = useFetchCards(CARDS_PATH, isFusionEnabled);

  useEffect(() => {
    if (allCards && myCards.length > 0 && isFusionEnabled) {
      const ksCards = myCards.filter((card) =>
        card.reference.includes('COREKS')
      );
      const defaultCards = myCards.filter((card) =>
        card.reference.includes('CORE_')
      );

      const temporaryCards = fusionSets(ksCards, defaultCards, allCards);
      setFusionCards(temporaryCards);
    } else {
      setFusionCards(myCards);
    }
  }, [allCards, isFusionEnabled, myCards]);

  useEffect(() => {
    const fetchCollection = async () => {
      const result = await getCollection(user);
      result && dispatch(setCollectionCards(result));
    };
    if (user?.accessToken) fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return fusionCards;
};

export default useMyCards;
