import { useTranslation } from 'react-i18next';

interface BlockProperties {
  number: number;
  label: string;
  isFit?: boolean;
}

const Block = ({ number, label, isFit }: BlockProperties) => {
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col justify-center ${isFit ? 'w-fit h-fit' : 'w-full h-full'} p-2 lg:px-4 rounded shadow-md font-chillaxMedium bg-darkBlue text-white`}
    >
      <h2 className="lg:text-4xl text-3xl font-bold">{number}</h2>
      <p className="font-light font lg:text-xs text-xs lowercase">{t(label)}</p>
    </div>
  );
};

export default Block;
