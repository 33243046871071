import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '../constants/constants';
import { CardType, SortedCard } from '../types/card';
import { drawBoosterPack } from '../utils/boosterUtilities';

interface SealedSimulatorProperties {
  sortedCards: SortedCard | undefined;
  version: string;
  nbBooster: number;
}

const useSealedSimulator = ({
  sortedCards,
  version,
  nbBooster = 6,
}: SealedSimulatorProperties) => {
  const [openedCards, setOpenedCards] = useState<CardType[]>([]);
  const [deckCards, setDeckCards] = useState<CardType[]>([]);
  const [opened, setOpened] = useState<boolean>(false);

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const handleOpenPacks = () => {
    let newOpenedCards: CardType[] = [];

    for (let index = 0; index < nbBooster; index++) {
      newOpenedCards = [
        ...newOpenedCards,
        ...drawBoosterPack(sortedCards, version, currentLanguage),
      ];
    }

    setOpenedCards(newOpenedCards);
    setOpened(true);
  };

  const resetPacks = () => {
    setOpenedCards([]);
    setDeckCards([]);
    setOpened(false);
  };

  const handleInputPacks = (cards: CardType[]) => {
    setOpenedCards(cards);
    setDeckCards([]);
    setOpened(true);
  };

  const addDeckCard = (card: CardType) => {
    setOpenedCards((previous) => {
      const index = previous.findIndex((c) => c.reference === card.reference);
      if (index !== -1) {
        const updatedCards = [...previous];
        updatedCards.splice(index, 1);
        return updatedCards;
      }
      return previous;
    });
    setDeckCards((previous) => [...previous, card]);
  };

  const addCardBack = (card: CardType) => {
    setDeckCards((previous) => {
      const index = previous.findIndex((c) => c.reference === card.reference);
      if (index !== -1) {
        const updatedCards = [...previous];
        updatedCards.splice(index, 1);
        return updatedCards;
      }
      return previous;
    });
    setOpenedCards((previous) => [...previous, card]);
  };

  return {
    openedCards,
    sortedCards,
    deckCards,
    opened,
    handleInputPacks,
    handleOpenPacks,
    resetPacks,
    addDeckCard,
    addCardBack,
  };
};

export default useSealedSimulator;
