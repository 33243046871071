import { useState } from 'react';

import { useDispatch } from 'react-redux';

import api from '../api/axiosInstance';
import { setTeams } from '../store/slices/userSlice';
import { Team } from '../types/team';
import { User } from '../types/user';
import { handleError } from '../utils/errorHandler';

export interface CreateTeamProperties {
  teamName: string;
  user: User;
}

export interface JoinTeamProperties {
  teamNumber: string;
  user: User;
}

export interface TeamIdProperties {
  teamId: string;
  user: User;
}

export const useTeam = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  // Créer une équipe
  const createTeam = async ({
    teamName,
    user,
  }: CreateTeamProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/team/create',
        { name: teamName },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setTeams(response.data.teams));
        setIsLoading(false);
        return response.data.teamNumber;
      } else {
        setError("Erreur lors de la création de l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Rejoindre une équipe
  const joinTeam = async ({
    teamNumber,
    user,
  }: JoinTeamProperties): Promise<boolean | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/team/join',
        { teamNumber },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setTeams(response.data.teams));
        setIsLoading(false);
        return true;
      } else {
        setError("Erreur lors de la tentative de rejoindre l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Quitter une équipe
  const leaveTeam = async ({
    teamId,
    user,
  }: TeamIdProperties): Promise<boolean | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/team/leave',
        { teamId },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setTeams(response.data.teams));
        setIsLoading(false);
        return true;
      } else {
        setError("Erreur lors de la tentative de quitter l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Supprimer une équipe
  const deleteTeam = async ({
    teamId,
    user,
  }: TeamIdProperties): Promise<boolean | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/team/delete',
        { teamId },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(setTeams(response.data.teams));
        setIsLoading(false);
        return true;
      } else {
        setError("Erreur lors de la suppression de l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer les détails d'une équipe
  const getTeamDetails = async ({
    teamId,
    user,
  }: TeamIdProperties): Promise<Team | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/team/${teamId}`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as Team;
      } else {
        setError("Erreur lors de la récupération des détails de l'équipe");
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return {
    createTeam,
    joinTeam,
    leaveTeam,
    deleteTeam,
    getTeamDetails,
    isLoading,
    error,
  };
};
