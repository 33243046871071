import React from 'react';

import { useTranslation } from 'react-i18next';

import useDeckValidation from '../hooks/useDeckValidation';
import { CardType } from '../types/card';
import { CardDeckType } from '../types/deck';
import { particleIdToName } from '../utils/cardUtilities';

interface DeckValidationBannerProperties {
  baseDeckCards: CardDeckType[];
  myCards: CardType[];
  allCards: CardType[];
}

const DeckValidationBanner: React.FC<DeckValidationBannerProperties> = ({
  baseDeckCards,
  myCards,
  allCards,
}) => {
  const { t, i18n } = useTranslation();
  const { isLegal, errors } = useDeckValidation(baseDeckCards, myCards);
  const currentLanguage = i18n.language;

  if (isLegal) {
    return <></>;
  }

  return (
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-4 md:mx-0"
      role="alert"
    >
      <ul className="font-chillaxMedium">
        {errors.map((error, index) => (
          <li key={index} className="mt-1">
            {t(`deck.errors.${error.code}`)}
            {error.references && (
              <span className="font-chillaxBold">
                {error.references
                  .map(
                    (reference) =>
                      `${reference.quantity} ${particleIdToName(allCards, reference.particuleId, currentLanguage)}`
                  )
                  .join(', ')}
              </span>
            )}
            {error.nbCards && (
              <span className="font-chillaxBold">{error.nbCards}</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DeckValidationBanner;
