import { useState } from 'react';

import api from '../api/axiosInstance';
import { CardType } from '../types/card';
import { CardDeckType, DeckType } from '../types/deck';
import { User } from '../types/user';
import { handleError } from '../utils/errorHandler';

interface CreateDeckProperties {
  deckName: string;
  deckHero: CardType;
  deckFaction: string;
  deckCards?: CardDeckType[];
  deckTeam?: string;
  isPublic?: boolean;
  user: User;
}

interface UpdateDeckProperties {
  deckId: string;
  deckCards: CardDeckType[];
  deckHero?: CardType;
  deckName?: string;
  deckTeam?: string;
  isPublic?: boolean;
  user: User;
}

interface DeckDetailsProperties {
  deckId: string;
  user: User;
}

interface DeckPublicProperties {
  deckId: string;
}

export const useDeck = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Créer un deck
  const createDeck = async ({
    deckName,
    deckHero,
    deckCards,
    deckFaction,
    isPublic,
    deckTeam,
    user,
  }: CreateDeckProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post(
        '/deck/create',
        {
          name: deckName,
          hero: deckHero,
          faction: deckFaction,
          team: deckTeam,
          cards: deckCards,
          isPublic,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 201) {
        setIsLoading(false);
        return response.data;
      } else {
        setError('Erreur lors de la création du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const updateDeck = async ({
    deckId,
    deckName,
    deckHero,
    deckCards,
    isPublic,
    deckTeam,
    user,
  }: UpdateDeckProperties): Promise<DeckType | undefined> => {
    setIsLoading(true);
    setError(undefined);
    try {
      const response = await api.put(
        '/deck/' + deckId,
        {
          name: deckName,
          team: deckTeam,
          hero: deckHero,
          cards: deckCards,
          isPublic,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        return response.data;
      } else {
        setError('Erreur lors de la création du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const deleteDeck = async ({
    deckId,
    user,
  }: DeckDetailsProperties): Promise<string | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.delete('/deck/' + deckId, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data;
      } else {
        setError('Erreur lors de la suppresion du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer des decks
  const getUserDecks = async (user: User): Promise<DeckType[] | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/deck/private`, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as DeckType[];
      } else {
        setError('Erreur lors de la récupération des decks');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const getPublicDecks = async (): Promise<DeckType[] | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/deck/public`);

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as DeckType[];
      } else {
        setError('Erreur lors de la récupération des decks');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const getDeckDetail = async ({
    user,
    deckId,
  }: DeckDetailsProperties): Promise<DeckType | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get('/deck/details/' + deckId, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as DeckType;
      } else {
        setError('Erreur lors de la récupération des détails du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  const getPublicDeckDetail = async ({
    deckId,
  }: DeckPublicProperties): Promise<DeckType | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get('/deck/public/' + deckId);

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as DeckType;
      } else {
        setError('Erreur lors de la récupération des détails du deck');
        setIsLoading(false);
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return {
    createDeck,
    updateDeck,
    deleteDeck,
    getPublicDecks,
    getUserDecks,
    getPublicDeckDetail,
    getDeckDetail,
    isLoading,
    error,
  };
};
