import React from 'react';

import Modal from '@mui/material/Modal';
import { t } from 'i18next';

import { useCardInputAdvanced } from '../../hooks/useCardInputAdvanced';
import { CardType } from '../../types/card';
import CardList from '../CardList';

interface CardSelectionModalProperties {
  isOpen: boolean;
  onClose: (cardInput: string) => void;
  cards: CardType[];
  currentCards: string;
  quantity?: boolean;
  faction?: string;
}

const CardSelectionModal: React.FC<CardSelectionModalProperties> = ({
  isOpen,
  onClose,
  cards,
  quantity = false,
  currentCards,
  faction,
}) => {
  const { cardInput, selectedCards, handleCardInputChange, setSelectedCards } =
    useCardInputAdvanced(cards, currentCards);

  const handleConfirmSelection = () => {
    onClose(cardInput);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => handleConfirmSelection()}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige p-4 rounded shadow-lg lg:w-2/3 h-5/6 overflow-x-auto relative border-4 border-darkBlue">
        <div className="flex flex-col justify-center w-full p-4">
          <h2 className="text-xl font-chillaxBold">
            {t('selectCard.importTitle')}
          </h2>
          <textarea
            rows={4}
            placeholder={t('actions.enterYourDeckList')}
            value={cardInput}
            onChange={handleCardInputChange}
            className="block w-full shadow sm:text-xs p-1 rounded-md border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
          />
        </div>
        <CardList
          cards={cards}
          setSelectedCards={setSelectedCards}
          handleClose={handleConfirmSelection}
          selectedCards={selectedCards}
          quantity={quantity}
          faction={faction}
          isExchange
          isClose
          isDeckBuilder
        />
      </div>
    </Modal>
  );
};

export default CardSelectionModal;
