import React from 'react';

import { FiMinus, FiPlus } from 'react-icons/fi';
import { TbArrowsDoubleNwSe } from 'react-icons/tb';

import Button from '../../components/Button';

interface SealedActionsProperties {
  particleId: string;
  addDeckParticulId?: (particleId: string) => void;
  removeDeckParticulId?: (particleId: string) => void;
  changeRarity?: (particleId: string) => void;
}

const DeckCardActions: React.FC<SealedActionsProperties> = ({
  particleId,
  addDeckParticulId,
  removeDeckParticulId,
  changeRarity,
}) => {
  return (
    <div className="flex flex-col justify-center relative">
      <div className="flex flex-col justify-center">
        <div className="flex space-x-2">
          {removeDeckParticulId && (
            <Button
              full
              top
              small
              onClick={() => removeDeckParticulId(particleId)}
            >
              <FiMinus />
            </Button>
          )}
          {changeRarity && (
            <Button full top small onClick={() => changeRarity(particleId)}>
              <TbArrowsDoubleNwSe />
            </Button>
          )}
          {addDeckParticulId && (
            <Button
              full
              top
              small
              onClick={() => addDeckParticulId(particleId)}
            >
              <FiPlus />
            </Button>
          )}
        </div>
        <div className={`border-b-4 border-darkBlue`} />
      </div>
    </div>
  );
};

export default DeckCardActions;
