import React, { useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CARDS_PATH } from '../../constants/constants';
import { TABS_SHARED_COMPARE_CARDS } from '../../constants/tabs';
import useFetchCards from '../../hooks/useFetchCards';
import useMyCards from '../../hooks/useMyCards';
import { RootState } from '../../store/store';
import { CardType } from '../../types/card';
import {
  compareCardCollections,
  getExcessCards,
  getMissingCards,
} from '../../utils/cardUtilities';
import CardList from '../CardList';
import Tabs from '../Tabs';

interface CompareModalProperties {
  isOpen: boolean;
  onClose: () => void;
  sharedMissingCards: CardType[];
  sharedExcessCards: CardType[];
}

const CompareModal: React.FC<CompareModalProperties> = ({
  isOpen,
  onClose,
  sharedMissingCards,
  sharedExcessCards,
}) => {
  const { t } = useTranslation();
  const [myMissingForHim, setMyMissingForHim] = useState<CardType[]>([]);
  const [hisExcessForMe, setHisExcessForMe] = useState<CardType[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('');

  const myCards = useMyCards();

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useFetchCards(CARDS_PATH, isFusionEnabled);

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );

  const myMissingCards =
    myCards &&
    allCards &&
    getMissingCards(myCards, allCards, rareQuantity, commonQuantity);
  const myExcessCards =
    myCards && getExcessCards(myCards, rareQuantity, commonQuantity);

  useEffect(() => {
    if (
      sharedMissingCards.length > 0 &&
      myExcessCards.length > 0 &&
      myMissingForHim.length === 0
    ) {
      const matchingForHim = compareCardCollections(
        sharedMissingCards,
        myExcessCards
      );
      setHisExcessForMe(matchingForHim);
    }

    if (
      myMissingCards &&
      sharedExcessCards.length > 0 &&
      myMissingCards.length > 0 &&
      hisExcessForMe.length === 0
    ) {
      const matchingForMe = compareCardCollections(
        sharedExcessCards,
        myMissingCards
      );
      setMyMissingForHim(matchingForMe);
    }

    if (selectedTab === '') {
      if (myMissingForHim.length > 0) {
        setSelectedTab(TABS_SHARED_COMPARE_CARDS.MY_MISSING.key);
      } else if (hisExcessForMe.length > 0) {
        setSelectedTab(TABS_SHARED_COMPARE_CARDS.SHARED_MISSING.key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedMissingCards, sharedExcessCards]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige p-4 rounded shadow-lg lg:w-2/3 h-5/6 space-y-4 overflow-auto relative border-4 border-darkBlue">
        {Object.keys(TABS_SHARED_COMPARE_CARDS).length > 0 ? (
          <>
            <Tabs
              tabs={TABS_SHARED_COMPARE_CARDS}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              full
            />
            {selectedTab === TABS_SHARED_COMPARE_CARDS.MY_MISSING.key && (
              <CardList cards={myMissingForHim} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_COMPARE_CARDS.SHARED_MISSING.key && (
              <CardList cards={hisExcessForMe} showCollectionFilter />
            )}
          </>
        ) : (
          <p className="text-center">{t('shared.noExchange')}</p>
        )}
      </div>
    </Modal>
  );
};

export default CompareModal;
