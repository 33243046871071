import React from 'react';

import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import DeckCardActions from './DeckCardActions';
import Card from '../../components/Card';
import { LANGUAGES } from '../../constants/constants';
import { CardType } from '../../types/card';
import { DeckCardsType } from '../../types/deck';
import { particleIdToName } from '../../utils/cardUtilities';
import { getTotalCardCount } from '../../utils/deckUtilities';

interface DeckCardListProperties {
  cards: DeckCardsType[];
  allCards: CardType[];
  isCreater?: boolean;
  addDeckParticulId: (particleId: string) => void;
  removeDeckParticulId: (particleId: string) => void;
  changeRarity: (particleId: string) => void;
}

const DeckCardList: React.FC<DeckCardListProperties> = ({
  cards,
  allCards,
  isCreater,
  addDeckParticulId,
  removeDeckParticulId,
  changeRarity,
}) => {
  const { i18n } = useTranslation();
  const overlapOffset = 20;
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  return (
    <div className="container mx-auto p-4 space-y-4 md:space-y-24">
      <div className="flex flex-wrap justify-center items-start w-full gap-10">
        {cards.map((deck, deckIndex) => {
          let unifiedIndex = -1;
          const totalCards = getTotalCardCount(deck);
          const name = particleIdToName(
            allCards,
            deck.particleId,
            currentLanguage
          );

          return (
            <div
              key={`${deck.particleId}-${deckIndex}`}
              className="grid grid-cols-1 gap-y-3 w-32 md:w-52"
            >
              <h3 className="text-center font-chillaxBold text-darkBlue whitespace-nowrap overflow-hidden text-ellipsis">
                {name}
              </h3>

              <div>
                {isCreater && (
                  <DeckCardActions
                    particleId={deck.particleId}
                    addDeckParticulId={addDeckParticulId}
                    removeDeckParticulId={removeDeckParticulId}
                    changeRarity={changeRarity}
                  />
                )}
                <div
                  className="relative w-full"
                  style={{
                    height: `${totalCards * overlapOffset + (isMobile ? 152 : 273)}px`,
                  }}
                >
                  {deck.cards.flatMap((card) => {
                    return Array.from({ length: card.quantity || 1 }).map(
                      () => {
                        unifiedIndex += 1;
                        return (
                          <div
                            key={`${card.reference}-${unifiedIndex}`}
                            className="absolute transition-transform duration-300"
                            style={{
                              bottom: `${unifiedIndex * overlapOffset}px`,
                              zIndex: deck.cards.length * 10 - unifiedIndex,
                            }}
                          >
                            <Card card={card} />
                          </div>
                        );
                      }
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeckCardList;
