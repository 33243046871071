import axios from 'axios';

import { CardAPI, CardStatsInfo, CardType } from '../types/card';
import { handleError } from '../utils/errorHandler';
import { formatUniqueCards } from '../utils/formatCards';

interface FetchUniqueCardsResult {
  uniqueCards: CardType[];
}

interface FetchPublicUniqueCardsResult {
  publicUniqueCards: CardType[];
  privateUniqueCardStats: CardStatsInfo[];
}

export const fetchPublicUniqueCards = async (
  uniqueCardStats: CardStatsInfo[],
  onProgressUpdate?: (message: string) => void
): Promise<FetchPublicUniqueCardsResult> => {
  const publicDataUniqueCards: CardAPI[] = [];
  const privateUniqueCardStats: CardStatsInfo[] = [];

  onProgressUpdate && onProgressUpdate(`0/${uniqueCardStats.length}`);

  for (const stat of uniqueCardStats) {
    try {
      const response = await axios.get(
        `https://api.altered.gg/cards/${stat.reference}`,
        {
          headers: {
            'Accept-Language': 'fr-fr',
          },
        }
      );

      const cardData: CardAPI = response.data;
      publicDataUniqueCards.push(cardData);

      onProgressUpdate &&
        onProgressUpdate(
          ` ${publicDataUniqueCards.length}/${uniqueCardStats.length}`
        );
    } catch {
      console.warn(`Private Card: ${stat.reference}.`);
      privateUniqueCardStats.push(stat);
    }
  }
  const publicUniqueCards = formatUniqueCards(
    publicDataUniqueCards,
    uniqueCardStats
  );

  return {
    publicUniqueCards,
    privateUniqueCardStats,
  };
};

export const fetchUniqueCards = async (
  token: string,
  uniqueCardStats: CardStatsInfo[],
  setErrorMessage: (error: string) => void,
  onProgressUpdate: (message: string) => void
): Promise<FetchUniqueCardsResult | undefined> => {
  try {
    const itemsPerPage = 36;
    let currentPage = 1;
    let totalPages = 1;
    let loadedUniqueCards = 0;

    const allUniqueCards: CardType[] = [];

    do {
      const cardsResponse = await axios.get('https://api.altered.gg/cards', {
        params: {
          itemsPerPage,
          collection: true,
          page: currentPage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'fr-fr',
        },
      });

      const cardData = cardsResponse.data['hydra:member'];
      const uniqueCardsOnPage = cardData.filter((card: CardAPI) =>
        uniqueCardStats?.some((stat) => stat.reference === card.reference)
      );
      const filteredCards: CardType[] = formatUniqueCards(
        uniqueCardsOnPage,
        uniqueCardStats
      );

      allUniqueCards.push(...filteredCards);
      loadedUniqueCards += uniqueCardsOnPage.length;

      onProgressUpdate(
        ` ${loadedUniqueCards}/${uniqueCardStats.length} (Uniques)`
      );

      const totalItems = cardsResponse.data['hydra:totalItems'];
      totalPages = Math.ceil(totalItems / itemsPerPage);
      currentPage += 1;
    } while (currentPage <= totalPages);

    return {
      uniqueCards: allUniqueCards,
    };
  } catch (error) {
    console.error('Error fetching unique cards:', error);
    handleError(error, setErrorMessage);
    throw error;
  }
};
