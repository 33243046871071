import React from 'react';

import RaritySelect from '../components/RaritySelect';

interface ShareOptionProperties {
  includeCards: boolean;
  onIncludeChange: (include: boolean) => void;
  rarityFilter: string[];
  onRarityChange: (rarities: string[]) => void;
  includeLabel: string;
  isUnique?: boolean;
}

const ShareOption: React.FC<ShareOptionProperties> = ({
  includeCards,
  onIncludeChange,
  rarityFilter,
  onRarityChange,
  includeLabel,
  isUnique,
}) => {
  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          checked={includeCards}
          onChange={(event) => onIncludeChange(event.target.checked)}
          id={includeLabel}
          className="form-checkbox outline-none accent-darkBlue"
        />
        <label htmlFor={includeLabel} className="font-chillaxBold">
          {includeLabel}
        </label>
      </div>
      {includeCards && (
        <div className="flex space-x-2 items-end">
          <RaritySelect
            selectedRarities={rarityFilter}
            onChange={onRarityChange}
            isUnique={isUnique}
          />
        </div>
      )}
    </div>
  );
};

export default ShareOption;
