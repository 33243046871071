import { LanguageDetectorModule } from 'i18next';

const CustomLanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const detectedLang =
      navigator.language || (navigator as Navigator).language;
    return detectedLang ? detectedLang.split('-')[0] : 'en';
  },
  init: () => {
    console.log('Language detector initialized');
  },
  cacheUserLanguage: (lng) => {
    console.log(`Caching user language: ${lng}`);
  },
};

export default CustomLanguageDetector;
