import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import Separator from '../components/Separator';
import { useLogout } from '../hooks/useLogout';
import LoginModal from '../parts/modals/LoginModal';
import RegisterModal from '../parts/modals/RegisterModal';
import { RootState } from '../store/store';

const Profil = () => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  const user = useSelector((state: RootState) => state.user);

  const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false);
  const [modalRegisterIsOpen, setModalRegisterIsOpen] = useState(false);

  const openModalLogin = () => setModalLoginIsOpen(true);
  const closeModalLogin = () => setModalLoginIsOpen(false);

  const openModalRegister = () => setModalRegisterIsOpen(true);
  const closeModalRegister = () => setModalRegisterIsOpen(false);

  return (
    <div
      className={`pt-16 pb-8 w-full shadow-xl overflow-hidden border-l-4 border-darkBlue bg-beige`}
      style={{ height: '100vh' }}
    >
      <div className="relative flex flex-col space-y-10 md:justify-between h-full pt-10 pb-20 md:pl-10 p-4 md:pt-4 md:pb-4 font-chillaxMedium text-xs md:text-base">
        {user.accessToken && (
          <div className="space-y-4">
            <div>
              <h3 className="text-xl font-chillaxBold capitalize">
                {user?.pseudo}
              </h3>
              <h3>{user?.email}</h3>
            </div>
            <Separator />
            {user?.betaTester && <h3>You're a beta tester!</h3>}
          </div>
        )}

        <div className="flex flex-col justify-center items-center space-y-2 h-screen">
          {user.accessToken ? (
            <div className="space-y-6">
              <Button onClick={logout}>{t('actions.logout')}</Button>
            </div>
          ) : (
            <>
              <Button onClick={openModalLogin}>{t('actions.login')}</Button>
              <Button onClick={openModalRegister}>
                {t('actions.register')}
              </Button>
            </>
          )}
        </div>
      </div>

      <LoginModal isOpen={modalLoginIsOpen} closeModal={closeModalLogin} />
      <RegisterModal
        isOpen={modalRegisterIsOpen}
        closeModal={closeModalRegister}
      />
    </div>
  );
};

export default Profil;
