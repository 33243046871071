import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import Separator from '../components/Separator';
import TeamActions from '../components/TeamActions';
import WhiteContainer from '../components/WhiteContainer';
import { LANGUAGES } from '../constants/constants';
import { useTeam } from '../hooks/useTeam';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import { RootState } from '../store/store';
import { Team } from '../types/team';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const TeamsPage: React.FC = () => {
  const { isLoading, error } = useTeam();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const user = useSelector((state: RootState) => state.user);

  if (isLoading) {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.teams') + ' | Altered Card';

  return (
    <Layout>
      <div className="container mx-auto p-4 space-y-10">
        <div className="space-y-4">
          <div className="space-y-2">
            <h1 className="text-2xl font-chillaxBold text-darkBlue">
              {t('teams.title')}
            </h1>
            <p className="text-darkBlue font-chillaxMedium">
              {t('teams.desc')}
            </p>
          </div>

          {user?._id && <TeamActions />}
        </div>

        <Separator />
        <div className="flex flex-wrap gap-4 justify-center md:justify-start">
          {error && <p className="text-red-500">{error}</p>}
          {user?.teams?.map((team: Team, index) => (
            <div key={`team-${index}`}>
              <WhiteContainer>
                <div className="p-6 space-y-6">
                  <div>
                    <h2 className="text-xl font-chillaxBold">{team.name}</h2>
                    <p className="font-chillaxRegular">
                      {t('filterTitle.number')}:{' '}
                      <span className="font-chillaxMedium">
                        {team.teamNumber}
                      </span>
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      isLink
                      to={getNavigateUrl(currentLanguage, `team/${team._id}`)}
                    >
                      {t('actions.checkTeam')}
                    </Button>
                  </div>
                </div>
              </WhiteContainer>
            </div>
          ))}
          {!user?._id && (
            <p className="text-red-500 font-chillaxBold">
              {t('teams.needConection')}
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TeamsPage;
