import React from 'react';

interface WhiteContainerProperties {
  children: React.ReactNode;

  isSelected?: boolean;
}

const WhiteContainer: React.FC<WhiteContainerProperties> = ({
  children,

  isSelected,
}) => {
  return (
    <div
      className={`flex flex-col rounded-lg shadow-lg relative bg-lightBlue ${isSelected ? 'border-4' : 'border-2'} border-darkBlue`}
    >
      {children}
    </div>
  );
};

export default WhiteContainer;
