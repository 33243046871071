import React from 'react';

import { useTranslation } from 'react-i18next';
import { HiSortAscending, HiSortDescending } from 'react-icons/hi';

import { SORT_DIRECTION } from '../constants/constants';

interface SortOption {
  mode: string;
  label: string;
  showIcon?: boolean;
  showCollection?: boolean;
}

interface DynamicSortButtonsProperties {
  sortMode: string;
  sortDirection?: string;
  setSortMode: (mode: string) => void;
  toggleSortDirection?: (direction?: string) => void;
  options: SortOption[];
  showCollectionFilter?: boolean;
}

const DynamicSortButtons: React.FC<DynamicSortButtonsProperties> = ({
  sortMode,
  sortDirection,
  setSortMode,
  toggleSortDirection,
  options,
  showCollectionFilter,
}) => {
  const { t } = useTranslation();

  const baseClasses = 'px-1 py-1 md:px-4 md:py-2 border-4 border-darkBlue';
  const activeClasses = 'bg-darkBlue text-white';
  const inactiveClasses = 'bg-lightBlue text-darkBlue';

  const getButtonClasses = (
    index: number,
    totalOptions: number,
    sortMode: string,
    option: SortOption
  ) => {
    let positionClasses = '';

    if (option.showCollection && !showCollectionFilter) {
      return 'hidden';
    }
    if (index === 0) {
      positionClasses = 'rounded-l-md border-r-0';
    } else if (index === totalOptions - 1) {
      positionClasses = 'rounded-l-0 rounded-r-md';
    } else {
      positionClasses = 'border-l-md border-r-0';
    }

    const modeClasses =
      sortMode === option.mode ? activeClasses : inactiveClasses;

    return `${baseClasses} ${positionClasses} ${modeClasses}`;
  };

  return (
    <div className="flex flex-col space-y-2 items-start">
      <div className="flex">
        {options.map((option, index) => (
          <button
            key={option.mode}
            className={getButtonClasses(
              index,
              options.length,
              sortMode,
              option
            )}
            onClick={() => {
              setSortMode(option.mode);
              if (toggleSortDirection) {
                if (option.showIcon && sortMode === option.mode) {
                  toggleSortDirection();
                } else if (option.showIcon && sortMode !== option.mode) {
                  toggleSortDirection(SORT_DIRECTION.ASCENDING);
                }
              }
            }}
          >
            {t(option.label)}
            {option.showIcon && (
              <>
                {sortMode === option.mode ? (
                  <>
                    {sortDirection === SORT_DIRECTION.ASCENDING ? (
                      <HiSortAscending className="inline ml-2" />
                    ) : (
                      <HiSortDescending className="inline ml-2" />
                    )}
                  </>
                ) : (
                  <HiSortAscending className="inline ml-2" />
                )}
              </>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DynamicSortButtons;
