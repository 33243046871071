import { FaThList } from 'react-icons/fa';
import { IoGrid } from 'react-icons/io5';

export const TABS_SEALED = {
  DRAW: { key: 'DRAW', label: 'navigation.drawTab' },
  DECK: { key: 'DECK', label: 'navigation.deckTab' },
};

export const TABS_DECKS = {
  MY_DECKS: {
    key: 'MY_DECKS',
    label: 'navigation.myDecksTab',
  },
  PUBLIC_DECKS: {
    key: 'PUBLIC_DECKS',
    label: 'navigation.publicDecksTab',
  },
  MY_TEAM_DECKS: {
    key: 'MY_TEAM_DECKS',
    label: 'navigation.teamDecksTab',
  },
};

export const TABS_DECKS_VIEW = {
  GRID: {
    key: 'GRID',
    icon: IoGrid,
  },
  LIST: {
    key: 'LIST',
    icon: FaThList,
  },
};

export const TABS_DECKS_CHECKER = {
  DECK: { key: 'DECK', label: 'navigation.deckTab' },
  MISSING: { key: 'DRAW', label: 'navigation.missing' },
};

export const TABS_CARDS = {
  CARDS: { key: 'CARD', label: 'navigation.exploration' },
  MY_CARD: { key: 'MY_CARD', label: 'navigation.owned' },
  MISSING: { key: 'MISSING', label: 'navigation.missing' },
  EXCESS: { key: 'EXCESS', label: 'navigation.surplus' },
};

export const TABS_CARDS1 = {
  CARDS: { key: 'CARD', label: 'navigation.exploration' },
  MY_CARD: { key: 'MY_CARD', label: 'navigation.owned' },
};

export const TABS_CARDS2 = {
  MISSING: { key: 'MISSING', label: 'navigation.missing' },
  EXCESS: { key: 'EXCESS', label: 'navigation.surplus' },
};

export const TABS_SHARED_CARDS = {
  COLLECTION: { key: 'COLLECTION', label: 'navigation.collection' },
  MISSING: { key: 'MISSING', label: 'navigation.missing' },
  EXCESS: { key: 'EXCESS', label: 'navigation.surplus' },
};

export const TABS_SHARED_COMPARE_CARDS = {
  MY_MISSING: { key: 'MY_MISSING', label: 'shared.cardCanGet' },
  SHARED_MISSING: {
    key: 'SHARED_MISSING',
    label: 'shared.cardCanGive',
  },
};

export const TABS_EXCHANGE = {
  PLAYER1: { key: 'PLAYER1', label: 'navigation.player1' },
  PLAYER2: { key: 'PLAYER2', label: 'navigation.player2' },
};

export const TABS_TOOLS = {
  EXPLORATION: { key: 'EXPLORATION', label: 'navigation.exploration' },
  COLLECTION: { key: 'COLLECTION', label: 'navigation.collection' },
  EXCHANGE: { key: 'EXCHANGE', label: 'navigation.simExchange' },
  DONATION: { key: 'DONATION', label: 'navigation.donation' },
  SEALED: { key: 'SEALED', label: 'navigation.sealedGenerator' },
};

export const TABS_TOOLS1 = {
  CARDS: { key: 'EXPLORATION', label: 'navigation.exploration' },
  COLLECTION: { key: 'COLLECTION', label: 'navigation.collection' },
};

export const TABS_TOOLS2 = {
  EXCHANGE: { key: 'EXCHANGE', label: 'navigation.simExchange' },
  DONATION: { key: 'DONATION', label: 'navigation.donation' },
  SEALED: { key: 'SEALED', label: 'navigation.sealedGenerator' },
};

export const TABS_MARKET = {
  MARKET: { key: 'MARKET', label: 'navigation.market' },
  FAVORITE: { key: 'FAVORITE', label: 'navigation.favorite' },
};

export const TABS_LOAN = {
  MY_LOAN: { key: 'MY_LOAN', label: 'navigation.myLoan' },
  GIVEN: { key: 'GIVEN', label: 'navigation.given' },
};
