import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import Separator from '../../components/Separator';
import { EXPORT_CARD_FORMAT } from '../../constants/constants';
import { useSharedCollection } from '../../hooks/useSharedCollection';
import { RootState } from '../../store/store';
import { CardType } from '../../types/card';
import { getExcessCards, getMissingCards } from '../../utils/cardUtilities';
import { generateExportDeckText } from '../../utils/exportUtilities';
import ShareOption from '../ShareOption';

interface ShareCollectionModalProperties {
  isOpen: boolean;
  onClose: () => void;
  myCards: CardType[];
  allCards: CardType[];
}

const ShareCollectionModal: React.FC<ShareCollectionModalProperties> = ({
  isOpen,
  onClose,
  myCards,
  allCards,
}) => {
  const { t } = useTranslation();
  const { error, createSharedCollection } = useSharedCollection();
  const [includeMyCards, setIncludeMyCards] = useState<boolean>(false);
  const [includeMissingCards, setIncludeMissingCards] =
    useState<boolean>(false);
  const [includeExcessCards, setIncludeExcessCards] = useState<boolean>(false);

  const [myCardsRarityFilter, setMyCardsRarityFilter] = useState<string[]>([]);
  const [missingCardRarityFilter, setMissingCardRarityFilter] = useState<
    string[]
  >([]);

  const [excessCardRarityFilter, setExcessCardRarityFilter] = useState<
    string[]
  >([]);

  const user = useSelector((state: RootState) => state.user);

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );

  const handleShare = async () => {
    let myCardsText: string | undefined;
    let missingCardsText: string | undefined;
    let excessCardsText: string | undefined;

    if (includeMyCards) {
      const filteredMyCards = myCards.filter(
        (card) =>
          myCardsRarityFilter.length === 0 ||
          myCardsRarityFilter.includes(card.rarity)
      );
      myCardsText = generateExportDeckText({
        cards: filteredMyCards,
        lineSeparator: '\n',
        needFilling: false,
        cardFormat: EXPORT_CARD_FORMAT.ID,
      });
    }

    if (includeMissingCards) {
      const missingCards = getMissingCards(
        myCards,
        allCards,
        rareQuantity,
        commonQuantity,
        missingCardRarityFilter.length > 0 ? missingCardRarityFilter : undefined
      );
      missingCardsText = generateExportDeckText({
        cards: missingCards,
        lineSeparator: '\n',
        needFilling: false,
        cardFormat: EXPORT_CARD_FORMAT.ID,
      });
    }

    if (includeExcessCards) {
      const excessCards = getExcessCards(
        myCards,
        rareQuantity,
        commonQuantity,
        excessCardRarityFilter.length > 0 ? excessCardRarityFilter : undefined
      );
      excessCardsText = generateExportDeckText({
        cards: excessCards,
        lineSeparator: '\n',
        needFilling: false,
        cardFormat: EXPORT_CARD_FORMAT.ID,
      });
    }

    const sharedId = await createSharedCollection({
      user,
      cards: myCardsText,
      missingCards: missingCardsText,
      excessCards: excessCardsText,
    });
    if (error) {
      toast.error("Une erreure c'est produite");
    } else {
      const collectionUrl = `https://altered-card.com/shared?binId=${sharedId}`;
      navigator.clipboard.writeText(collectionUrl);
      toast.info(
        'Lien de partage copié dans le presse-papiers : ' + collectionUrl
      );
    }

    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige space-y-4 p-6 rounded shadow-lg max-w-lg w-full border-4 border-darkBlue text-darkBlue">
        <div className="space-y-4">
          <ShareOption
            includeCards={includeMyCards}
            onIncludeChange={setIncludeMyCards}
            rarityFilter={myCardsRarityFilter}
            onRarityChange={setMyCardsRarityFilter}
            includeLabel={t('shared.mycards')}
            isUnique
          />
          <Separator />
          <ShareOption
            includeCards={includeMissingCards}
            onIncludeChange={setIncludeMissingCards}
            rarityFilter={missingCardRarityFilter}
            onRarityChange={setMissingCardRarityFilter}
            includeLabel={t('shared.missingCard')}
          />
          <Separator />
          <ShareOption
            includeCards={includeExcessCards}
            onIncludeChange={setIncludeExcessCards}
            rarityFilter={excessCardRarityFilter}
            onRarityChange={setExcessCardRarityFilter}
            includeLabel={t('shared.excessCards')}
          />
          <Separator />
        </div>
        <div className="flex justify-end space-x-4 mt-6">
          <Button onClick={onClose}>{t('actions.cancel')}</Button>
          <Button onClick={handleShare}>{t('actions.share')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareCollectionModal;
