import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchDeck } from '../../api/fetchDeck';
import Button from '../../components/Button';
import ModalTemplate from '../../components/ModalTemplate';
import { CARDS_PATH } from '../../constants/constants';
import { HERO } from '../../constants/decks';
import { useDeck } from '../../hooks/useDeck';
import useDeckBuilder from '../../hooks/useDeckBuilder';
import useFetchCards from '../../hooks/useFetchCards';
import i18n from '../../i18n';
import { RootState } from '../../store/store';
import { CardType } from '../../types/card';
import { getCardByReference } from '../../utils/cardUtilities';
import { getNavigateUrl } from '../../utils/getNavigateUrl';

interface CreateTeamModalProperties {
  isOpen: boolean;
  closeModal: () => void;
}

const CreateDeckModal: React.FC<CreateTeamModalProperties> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleTextToBaseDeckCard, baseDeckCards, errorMessage } =
    useDeckBuilder();

  const [deckName, setDeckName] = useState<string>('');
  const [deckHero, setDeckHero] = useState<CardType>();
  const [loadingDeck, setLoadingDeck] = useState<boolean>(false);
  const [deckFaction, setDeckFaction] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const { allCards } = useFetchCards(CARDS_PATH, true);

  const [deckLink, setDeckLink] = useState<string>('');

  const { createDeck, isLoading } = useDeck();

  const user = useSelector((state: RootState) => state.user);

  const handleHeroChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHero =
      allCards && getCardByReference(event.target.value, allCards);

    if (selectedHero) {
      setDeckHero(selectedHero);
      setDeckFaction(selectedHero.mainFaction);
    }
  };

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === 'Private') {
      setIsPublic(false);
    } else if (selectedValue === 'Public') {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (deckHero && deckName && deckFaction) {
      const deckID = await createDeck({
        deckName,
        deckHero,
        deckFaction,
        deckCards: baseDeckCards,
        isPublic,
        user,
      });

      if (deckID) {
        navigate(getNavigateUrl(i18n.language, 'decks/' + deckID));
      }
    }
  };

  const handleSubmitImport = async (event: React.FormEvent) => {
    setLoadingDeck(true);
    event.preventDefault();
    const deckDetails = await fetchDeck(
      deckLink,
      (event) => console.error(event) //TODO, gérer l'erreur dans l'interface
    );
    if (deckDetails && allCards) {
      setDeckName(deckDetails.name);
      setDeckHero(getCardByReference(deckDetails.hero, allCards));
      setDeckFaction(deckDetails.faction);
      await handleTextToBaseDeckCard(deckDetails.cards);
    }
    setLoadingDeck(false);
  };

  return (
    <ModalTemplate isOpen={isOpen} closeModal={closeModal}>
      <div className="md:w-[400px] space-y-8">
        <h2 className="text-xl font-chillaxBold">{t('actions.createDeck')}</h2>

        <form onSubmit={handleSubmitImport}>
          <div className="flex flex-col space-y-4">
            <input
              type="text"
              id="importDeckLink"
              value={deckLink}
              placeholder={t('deck.importDeckPlaceholder')}
              onChange={(event) => setDeckLink(event.target.value)}
              className="block w-full shadow sm:text-sm rounded-md h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />
            <Button full isSubmit disabled={isLoading}>
              {t('actions.import')}
            </Button>
          </div>
        </form>

        {loadingDeck ? (
          <div className="flex justify-center items-center w-full h-full">
            <h3 className="font-chillaxBold text-darkBlue">
              {t('loading.loadingDeck')}
            </h3>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-4">
              <input
                type="text"
                id="createDeckName"
                value={deckName}
                placeholder={t('deck.createDeckPlaceholder')}
                onChange={(event) => setDeckName(event.target.value)}
                className="block w-full shadow sm:text-sm rounded-md h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
              />

              <div>
                <select
                  id="createDeckHero"
                  value={deckHero?.reference}
                  onChange={handleHeroChange}
                  className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
                >
                  <option value="" disabled>
                    {t('deck.selectHeroPlaceholder')}
                  </option>
                  {Object.values(HERO).map((hero) => (
                    <option key={hero.reference} value={hero.reference}>
                      {t(hero.label)}
                    </option>
                  ))}
                </select>
              </div>

              <select
                id="createDeckTeam"
                value={isPublic ? 'Public' : 'Private'}
                onChange={handleTeamChange}
                className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
              >
                <option value="Private">{t('deck.private')}</option>
                <option value="Public">{t('deck.public')}</option>
              </select>

              <Button isSubmit disabled={isLoading}>
                {t('actions.create')}
              </Button>
            </div>
          </form>
        )}
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </div>
    </ModalTemplate>
  );
};

export default CreateDeckModal;
