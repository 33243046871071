import { useState } from 'react';

import api from '../api/axiosInstance';
import { User } from '../types/user';
import { handleError } from '../utils/errorHandler';

interface SharedCollection {
  id: string;
  cards: string;
  pseudo: string;
  missingCards: string;
  excessCards: string;
}

export interface CreateSharedCollectionProperties {
  user: User;
  cards?: string;
  missingCards?: string;
  excessCards?: string;
}

export const useSharedCollection = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createSharedCollection = async ({
    cards,
    missingCards,
    excessCards,
    user,
  }: CreateSharedCollectionProperties): Promise<
    SharedCollection | undefined
  > => {
    setIsLoading(true);
    setError(undefined);

    const pseudo =
      user?.pseudo || `Player-${Math.floor(Math.random() * 10_000)}`;

    try {
      const response = await api.post('/sharedCollection', {
        cards,
        missingCards,
        excessCards,
        pseudo,
      });

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as SharedCollection;
      } else {
        setIsLoading(false);
        setError('Erreur lors de la création de la collection partagée');
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  // Récupérer une collection partagée
  const getSharedCollection = async (
    id: string
  ): Promise<SharedCollection | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get(`/sharedCollection/${id}`);

      if (response.status === 200) {
        setIsLoading(false);
        return response.data as SharedCollection;
      } else {
        setIsLoading(false);
        setError('Erreur lors de la récupération de la collection partagée');
        return undefined;
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return { createSharedCollection, getSharedCollection, isLoading, error };
};
