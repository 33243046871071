import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  fetchPublicUniqueCards,
  fetchUniqueCards,
} from '../api/fetchUniqueCards';
import Button from '../components/Button';
import ButtonWithConfirmation from '../components/ButtonWithConfirmation';
import { CARDS_PATH } from '../constants/constants';
import { TABS_CARDS, TABS_CARDS1, TABS_CARDS2 } from '../constants/tabs';
import { useCardInput } from '../hooks/useCardInput';
import { useCollection } from '../hooks/useCollection';
import useFetchCards from '../hooks/useFetchCards';
import useMyCards from '../hooks/useMyCards';
import DiscordTuto from '../parts/DiscordTuto';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import CardSelectionModal from '../parts/modals/CardSelectionModal';
import ShareCollectionModal from '../parts/modals/ShareCollectionModal';
import UniqueCardsModal from '../parts/modals/UniqueCardsModal';
import RenderCardsList from '../parts/RenderCardsList';
import Tabs from '../parts/Tabs';
import { setCollectionCards } from '../store/slices/collectionCardsSlice';
import { RootState } from '../store/store';
import { CardStatsInfo, CardType } from '../types/card';
import { getExcessCards, getMissingCards } from '../utils/cardUtilities';
import { createMyCardsFromText } from '../utils/formatCards';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const Collection = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingLabel, setLoadingLabel] = useState<string>('');
  const [uniqueCardsToLoad, setUniqueCardsToLoad] = useState<CardStatsInfo[]>(
    []
  );
  const [cardsToLoad, setCardsToLoad] = useState<CardType[]>([]);
  const [uniqueModalOpen, setUniqueModalOpen] = useState(false);
  const myCards = useMyCards();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_CARDS.MY_CARD.key
  );

  const user = useSelector((state: RootState) => state.user);

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards: allCardsFusion } = useFetchCards(
    CARDS_PATH,
    isFusionEnabled
  );
  const { allCards } = useFetchCards(CARDS_PATH);

  const { updateCollection, error } = useCollection();

  const rareQuantity = useSelector(
    (state: RootState) => state.sidePanel.rareQuantity
  );

  const commonQuantity = useSelector(
    (state: RootState) => state.sidePanel.commonQuantity
  );
  const myCardsEmpty = !(myCards?.length > 0);

  useEffect(() => {
    toast.warning(error);
  }, [error]);

  useEffect(() => {
    if (myCardsEmpty) {
      setSelectedTab(TABS_CARDS.CARDS.key);
    } else {
      setSelectedTab(TABS_CARDS.MY_CARD.key);
    }
  }, [myCardsEmpty]);

  const {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  } = useCardInput();

  const missingCards =
    myCards &&
    allCardsFusion &&
    getMissingCards(myCards, allCardsFusion, rareQuantity, commonQuantity);
  const excessCards =
    myCards && getExcessCards(myCards, rareQuantity, commonQuantity);

  const handleCardInput = () => {
    if (!cardInput) {
      setErrorMessage(t('errors.invalidCardList'));
      return;
    }
    const cardEntries = cardInput.split('\n');
    const cardData: CardStatsInfo[] = cardEntries.map((entry) => {
      const [count, reference] = entry.split(' ');
      return {
        reference,
        inMyCollection: Number.parseInt(count, 10) || 0,
      } as CardStatsInfo;
    });

    if (allCards) {
      const { includedCards, uniqueCards } = createMyCardsFromText(
        allCards,
        cardData
      );
      if (includedCards.length > 0) {
        if (uniqueCards.length > 0) {
          tryLoadUnique(uniqueCards, includedCards);
        } else {
          dispatch(setCollectionCards(includedCards));
          if (user?.accessToken) updateCollection(includedCards, user);
        }
      } else {
        setErrorMessage(t('errors.invalidCardList'));
      }
    }
  };

  const handleCloseModal = () => {
    const selectedCardIds = selectedCards
      .map((card) => `${card.nbSelected || 1} ${card.reference}`)
      .join('\n');
    setCardInput(selectedCardIds);
    setModalIsOpen(false);
  };

  const handleGoToCoco = () => {
    navigate(getNavigateUrl(i18n.language, 'coco'));
  };

  const updateLoadingLabel = (message: string) => {
    setLoadingLabel(t('loading.loadingCollection') + message);
  };

  const tryLoadUnique = async (
    uniqueCards: CardStatsInfo[],
    includedCards: CardType[]
  ) => {
    setLoading(true);
    const { publicUniqueCards, privateUniqueCardStats } =
      await fetchPublicUniqueCards(uniqueCards, updateLoadingLabel);
    setLoading(false);

    const publicCards = [...includedCards, ...publicUniqueCards];
    setUniqueCardsToLoad(privateUniqueCardStats);
    setCardsToLoad(publicCards);
    if (privateUniqueCardStats.length > 0) {
      setUniqueModalOpen(true);
    } else {
      dispatch(setCollectionCards(publicCards));
      if (user?.accessToken) updateCollection(publicCards, user);
    }
  };

  const loadWithUniques = (token: string) => {
    setLoading(true);
    fetchUniqueCards(
      token,
      uniqueCardsToLoad,
      setErrorMessage,
      updateLoadingLabel
    )
      .then((result) => {
        if (result) {
          dispatch(setCollectionCards([...cardsToLoad, ...result.uniqueCards]));
          updateCollection([...cardsToLoad, ...result.uniqueCards], user);
        }
        setLoading(false);
        setLoadingLabel('');
        setUniqueModalOpen(false);
      })
      .catch((error_) => {
        setErrorMessage(error_.message);
        setLoading(false);
        setUniqueModalOpen(false);
      });
  };

  const loadWithoutUniques = () => {
    dispatch(setCollectionCards(cardsToLoad));
    if (user?.accessToken) updateCollection(cardsToLoad, user);
    setUniqueModalOpen(false);
  };

  document.title = t('navigation.collection') + ' | Altered Card';

  if (loading) {
    return <Loading label={loadingLabel} />;
  }

  return (
    <Layout handleShareCollection={() => setShareModalIsOpen(true)}>
      <div className="space-y-10">
        <div className="flex justify-center items-center w-full">
          <div className="w-full items-start px-6 lg:px-20">
            <div className="flex flex-col md:flex-row space-x-2 justify-center items-center">
              <div className="space-y-2">
                <Button onClick={() => setModalIsOpen(true)} full>
                  {t('actions.selectCards')}
                </Button>
                {user?.betaTester && (
                  <Button onClick={handleGoToCoco}>
                    {t('actions.loadCollection')}
                  </Button>
                )}
              </div>
              <span className="font-chillaxBold text-darkBlue">
                {t('connecter.or')}
              </span>
              <div className="flex flex-col w-full">
                {/* Error Message */}
                {errorMessage && (
                  <span className="text-red-600">{errorMessage}</span>
                )}
                <div className="flex justify-center w-full">
                  <textarea
                    rows={4}
                    placeholder={t('actions.enterYourCards')}
                    value={cardInput}
                    onChange={handleCardInputChange}
                    className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                  />
                  <ButtonWithConfirmation
                    onConfirm={handleCardInput}
                    confirmText={t('actions.confirm')}
                    cancelText={t('actions.cancel')}
                  >
                    {myCards && !myCardsEmpty
                      ? t('actions.refetchcard')
                      : t('actions.fetchcard')}
                  </ButtonWithConfirmation>
                </div>
              </div>
            </div>
            <DiscordTuto />
          </div>
        </div>
        {/* Cards List Section */}
        <div className="space-y-2 lg:space-y-8">
          {myCards && (
            <>
              <div className="hidden md:block">
                <Tabs
                  tabs={TABS_CARDS}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  small
                  full
                />
              </div>
              <div className="md:hidden space-y-2">
                <Tabs
                  tabs={TABS_CARDS1}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  small
                  full
                />
                <Tabs
                  tabs={TABS_CARDS2}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  small
                  full
                />
              </div>
            </>
          )}

          <RenderCardsList
            selectedTab={selectedTab}
            myCards={myCards}
            allCards={allCards || []}
            missingCards={missingCards}
            excessCards={excessCards}
          />
        </div>
        {/* Modals */}
        <CardSelectionModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          cards={allCardsFusion || []}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          quantity
        />
        {/* A modifier, il faudrait pouvoir désactiver la fusion ici sans avoir les
        cartes manquante des différents sets */}
        {myCards && allCardsFusion && (
          <ShareCollectionModal
            isOpen={shareModalIsOpen}
            onClose={() => setShareModalIsOpen(false)}
            myCards={myCards}
            allCards={allCardsFusion}
          />
        )}
        <UniqueCardsModal
          isOpen={uniqueModalOpen}
          closeModal={() => setUniqueModalOpen(false)}
          loadWithUniques={loadWithUniques}
          loadWithoutUniques={loadWithoutUniques}
        />
      </div>
    </Layout>
  );
};

export default Collection;
