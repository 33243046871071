import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '../components/Button';
import Separator from '../components/Separator';
import { CARDS_PATH, VERSION } from '../constants/constants';
import { TABS_SEALED } from '../constants/tabs';
import useFetchCards from '../hooks/useFetchCards';
import useSealedSimulator from '../hooks/useSealedSimulator';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import SealedActions from '../parts/SealedActions';
import Tabs from '../parts/Tabs';
import { handleTextToCardWithRepetitions } from '../utils/cardUtilities';

const Sealed: React.FC = () => {
  const { t } = useTranslation();
  const [list, setList] = useState('');
  const [selectedTab, setSelectedTab] = useState<string>(TABS_SEALED.DRAW.key);
  const [nbBooster, setNbBooster] = useState<number>(7);
  const [version, setVersion] = useState<string>(VERSION.DEFAULT);
  const { sortedCards, allCards, loading, error } = useFetchCards(
    CARDS_PATH,
    false,
    version
  );
  const {
    opened,
    openedCards,
    deckCards,
    handleOpenPacks,
    handleInputPacks,
    resetPacks,
    addDeckCard,
    addCardBack,
  } = useSealedSimulator({ sortedCards, version, nbBooster });

  if (loading) {
    return <Loading label={t('loading.loadingCards')} />;
  }

  if (error) {
    return <p className="text-xl text-red-500">{error}</p>;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const hasCoreKSCard = event.target.value.includes('_COREKS_');
    const newVersion = hasCoreKSCard ? VERSION.KS : VERSION.DEFAULT;

    setVersion(newVersion);
    setList(event.target.value);
  };

  const handleValidate = () => {
    if (list && allCards) {
      const temporaryCards = handleTextToCardWithRepetitions(list, allCards);
      if (temporaryCards && temporaryCards?.length > 0)
        handleInputPacks(temporaryCards);
    }
  };

  const selectView = () => {
    if (selectedTab === TABS_SEALED.DRAW.key) {
      return (
        <CardList
          cards={openedCards}
          isSealed
          isExport
          addDeckCard={addDeckCard}
        />
      );
    } else if (selectedTab === TABS_SEALED.DECK.key) {
      return (
        <CardList
          cards={deckCards}
          isSealed
          isExport
          addCardBack={addCardBack}
        />
      );
    }
  };

  document.title = t('navigation.sealedGenerator') + ' | Altered Card';

  return (
    <Layout>
      <div className="space-y-8">
        <BackButton to="tools" title={t('tools.sealed.title')} />
        <div className="space-y-4 lg:space-y-8 pt-6 lg:pt-10">
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 justify-center items-center">
            <SealedActions
              opened={opened}
              handleOpenPacks={handleOpenPacks}
              resetPacks={resetPacks}
              nbBooster={nbBooster}
              setNbBooster={setNbBooster}
              version={version}
              setVersion={setVersion}
            />
            <span className="font-chillaxBold text-darkBlue">
              {t('connecter.or')}
            </span>
            <div className="flex justify-center w-full md:w-1/3">
              <textarea
                rows={4}
                placeholder={t('actions.enterCards')}
                value={list}
                onChange={handleInputChange}
                className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
              />
              <Button onClick={handleValidate} isVertical right>
                {t('actions.validate')}
              </Button>
            </div>
          </div>
          {opened && (
            <>
              <Tabs
                tabs={TABS_SEALED}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                isSealed
                full
              />
              <Separator desktop />
              <div className="grid grid-cols-1 lg:grid-cols-2">
                <div className="lg:hidden">{selectView()}</div>
                <div className="hidden lg:block">
                  <CardList
                    cards={openedCards}
                    isExport
                    isSealed
                    addDeckCard={addDeckCard}
                  />
                </div>
                <div className="border-l-4 border-darkBlue hidden lg:block">
                  <CardList
                    cards={deckCards}
                    isExportSealed
                    isSealed
                    isDeck
                    addCardBack={addCardBack}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Sealed;
