import React, { useEffect, useState } from 'react';

import { t } from 'i18next';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { TbCardsFilled } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Separator from '../../components/Separator';
import WhiteContainer from '../../components/WhiteContainer';
import { LANGUAGES } from '../../constants/constants';
import { useDeck } from '../../hooks/useDeck';
import useDeckValidation from '../../hooks/useDeckValidation';
import useMyCards from '../../hooks/useMyCards';
import i18n from '../../i18n';
import { RootState } from '../../store/store';
import { DeckType } from '../../types/deck';
import { calculateSummary } from '../../utils/cardUtilities';
import { getNavigateUrl } from '../../utils/getNavigateUrl';

interface DeckPresentationProperties {
  baseDeck: DeckType;
  publicDeck?: boolean;
  refreshDecks?: () => void;
}

const DeckPresentation: React.FC<DeckPresentationProperties> = ({
  baseDeck,
  publicDeck,
  refreshDecks,
}) => {
  const navigate = useNavigate();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const user = useSelector((state: RootState) => state.user);
  const { getDeckDetail, getPublicDeckDetail, deleteDeck } = useDeck();
  const [deck, setDeck] = useState<DeckType>();
  const [isDeckLoaded, setIsDeckLoaded] = useState(false);
  const myCards = useMyCards();
  const { isLegal } = useDeckValidation(baseDeck.cards, myCards);
  const summary = calculateSummary(baseDeck.cards);

  useEffect(() => {
    const fetchDecks = async (deckId: string) => {
      const deck = publicDeck
        ? await getPublicDeckDetail({ deckId })
        : await getDeckDetail({ user, deckId });
      if (deck) {
        setDeck(deck);
        setIsDeckLoaded(true);
      }
    };
    if (baseDeck._id) {
      fetchDecks(baseDeck._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDeckClick = (deckId: string) => {
    navigate(getNavigateUrl(i18n.language, 'decks/' + deckId));
  };

  const handleDeleteDeck = (event: React.FormEvent) => {
    event.stopPropagation();
    deleteDeck({ user, deckId: baseDeck?._id });
    if (refreshDecks) refreshDecks();
  };

  if (!isDeckLoaded) return <></>;

  const createBackgroundImage = () => {
    if (deck) {
      const backgroundImage = `https://altered-prod-eu.s3.amazonaws.com/Art/CORE/CARDS/${deck?.hero?.reference?.slice(0, -2)}/${deck?.hero?.reference}_HERO_THUMB.jpg`;
      return backgroundImage;
    }
  };

  return (
    <>
      {deck && (
        <WhiteContainer>
          <div
            className="cursor-pointer flex w-full h-full bg-darkBlue bg-cover bg-no-repeat"
            onClick={() => handleOnDeckClick(deck._id)}
            style={{
              backgroundImage: `url(${createBackgroundImage()})`,
            }}
          >
            <div className="flex justify-between w-full p-5 bg-darkBlue50 text-white">
              <div className="flex flex-col space-y-2 overflow-hidden">
                <div className="flex flex-col">
                  <h3 className="font-chillaxBold text-2xl whitespace-nowrap overflow-hidden text-ellipsis">
                    {deck.name}
                  </h3>
                  <p className="font-chillaxRegular">
                    {deck?.hero?.name?.[currentLanguage]}
                  </p>
                </div>
                <Separator color="border-white" />
                <div className="flex pt-2 space-x-4 justify-start items-end">
                  {deck.isPublic ? (
                    <FaLockOpen className="w-6 h-6" />
                  ) : (
                    <FaLock className="w-6 h-6" />
                  )}
                  <span className="pl-2 font-chillaxBold h-fit flex space-x-1 justify-center items-center">
                    <TbCardsFilled className="w-6 h-6" />
                    <p>{summary.total}</p>
                  </span>
                </div>
              </div>
              <div
                className={`flex flex-col ${publicDeck ? 'justify-end' : 'justify-between'} items-end`}
              >
                {!publicDeck && (
                  <RiDeleteBin2Fill
                    className="w-6 h-6 hover:scale-110 transition-all transition-150"
                    onClick={handleDeleteDeck}
                  />
                )}
                <span
                  className={`font-chillaxBold px-2 rounded whitespace-nowrap ${isLegal ? 'bg-green-600' : 'bg-red-600'}`}
                >
                  {isLegal ? t('deck.legal') : t('deck.illegal')}
                </span>
              </div>
            </div>
          </div>
        </WhiteContainer>
      )}
    </>
  );
};

export default DeckPresentation;
