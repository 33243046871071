import { isUnique } from './cardUtilities';
import { CARD_TYPE } from '../constants/constants';
import {
  CardAPI,
  CardStatsAPI,
  CardStatsInfo,
  CardType,
  TradeListCardAPI,
} from '../types/card';
import { CardDeckType } from '../types/deck';

export const formatCards = (cards: CardStatsAPI[]): CardStatsInfo[] => {
  return cards.map((card: CardStatsAPI) => ({
    reference: card.reference,
    foiled: card.foiled,
    inMyCollection: card.inMyCollection,
    inMyTradelist: card.inMyTradelist,
    inMyWantlist: card.inMyWantlist,
  }));
};

export const formatCardsFriend = (
  cards: TradeListCardAPI[]
): CardStatsInfo[] => {
  return cards
    .filter((card) => !isUnique(card.reference))
    .map((card: TradeListCardAPI) => ({
      quantity: card.quantity,
      inMyCollection: card.inMyCollection,
      reference: card.reference,
    }));
};

export const formatUniqueCardsFriend = (
  cards: TradeListCardAPI[],
  userId: string
): CardType[] => {
  return cards
    .filter((card) => isUnique(card.reference))
    .map((card) => ({
      reference: card.reference,
      rarity: CARD_TYPE.UNIQUE,
      owner: userId,
      lastUpdate: new Date().toISOString(),
      name: {
        en: card.name,
        fr: card.name,
        es: card.name,
        it: card.name,
        de: card.name,
      },
      imagePath: {
        en: card.imagePath,
        fr: card.imagePath,
        es: card.imagePath,
        it: card.imagePath,
        de: card.imagePath,
      },
      inMyCollection: card.inMyCollection,
      type: '',
      subtypes: [],
      mainFaction: '',
      collectorNumberFormatted: {},
    }));
};

export const formatUniqueCards = (
  uniqueCards: CardAPI[],
  uniqueCardStats: CardStatsInfo[]
): CardType[] => {
  return uniqueCards.map((card) => {
    // Find the matching stats for the current card
    const matchingStats = uniqueCardStats.find(
      (stat) => stat.reference === card.reference
    );

    // If no matching stats are found, provide default values
    const inMyCollection = matchingStats?.inMyCollection ?? 0;
    const inMyTradelist = matchingStats?.inMyTradelist ?? 0;
    const inMyWantlist = matchingStats?.inMyWantlist ?? false;
    const foiled = matchingStats?.foiled ?? false;

    return {
      reference: card.reference,
      //id: card.id,
      type: card.cardType.reference,
      /*subtypes: card.cardSubTypes
        ? card.cardSubTypes.map((subtype: any) => subtype.reference)
        : [],*/
      subtypes: [],
      mainFaction: card.mainFaction.reference,
      rarity: card.rarity.reference,
      name: {
        en: card.name,
        fr: card.name,
        es: card.name,
        it: card.name,
        de: card.name,
      },
      imagePath: {
        en: card.imagePath,
        fr: card.imagePath,
        es: card.imagePath,
        it: card.imagePath,
        de: card.imagePath,
      },
      collectorNumberFormatted: {
        en: card.collectorNumberFormatted,
        fr: card.collectorNumberFormatted,
        es: card.collectorNumberFormatted,
        it: card.collectorNumberFormatted,
        de: card.collectorNumberFormatted,
      },
      qrUrlDetail: card.qrUrlDetail,
      elements: card.elements,
      inMyCollection: inMyCollection,
      inMyTradelist: inMyTradelist,
      inMyWantlist: inMyWantlist,
      foiled: foiled,
      //latestAddition: latestAddition,
    };
  });
};

export const createTextFromCards = (
  cards: (CardDeckType | CardType)[]
): string => {
  const cardTextIds = cards
    .map((card) => {
      const quantityOrSelected =
        'quantity' in card ? card.quantity : card.nbSelected || 1;
      return `${quantityOrSelected} ${card.reference}`;
    })
    .join('\n');
  return cardTextIds;
};

export const createMyCardsFromText = (
  allCards: CardType[],
  cardInfos: CardStatsInfo[]
): {
  includedCards: CardType[];
  excludedCards: string[];
  uniqueCards: CardStatsInfo[];
} => {
  const includedCards: CardType[] = [];
  const excludedCards: string[] = [];
  const uniqueCards: CardStatsInfo[] = [];

  for (const info of cardInfos) {
    const matchedCard = allCards.find(
      (card) => card.reference === info.reference
    );

    if (matchedCard) {
      includedCards.push({
        ...matchedCard,
        inMyCollection: info.inMyCollection,
        inMyTradelist: info.inMyTradelist,
        inMyWantlist: info.inMyWantlist,
        latestAddition: info.latestAddition,
      });
    } else {
      if (isUnique(info.reference)) {
        uniqueCards.push({
          reference: info.reference,
          inMyCollection: 1,
        });
      } else {
        excludedCards.push(info.reference);
      }
    }
  }

  return {
    includedCards,
    excludedCards,
    uniqueCards,
  };
};

export const createDeckCardsFromText = (
  allCards: CardType[],
  cardInfos: CardStatsInfo[],
  myCards?: CardType[]
): {
  includedCards: CardDeckType[];
  excludedCards: string[];
  excludedUniqueCards: CardStatsInfo[];
} => {
  const includedCards: CardDeckType[] = [];
  const excludedCards: string[] = [];
  const excludedUniqueCards: CardStatsInfo[] = [];

  for (const info of cardInfos) {
    const matchedCard = allCards.find(
      (card) => card.reference === info.reference
    );
    const matchedCollectionCard =
      myCards && myCards.find((card) => card.reference === info.reference);

    if (matchedCard) {
      includedCards.push({
        ...matchedCard,
        quantity: info.quantity || 1,
        inMyCollection: matchedCollectionCard?.inMyCollection || 0,
      });
    } else {
      if (isUnique(info.reference)) {
        excludedUniqueCards.push({
          reference: info.reference,
          inMyCollection: 1,
        });
      } else {
        excludedCards.push(info.reference);
      }
    }
  }

  return {
    includedCards,
    excludedCards,
    excludedUniqueCards,
  };
};
