import React from 'react';

import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';

import Separator from '../components/Separator';

export interface Tab {
  key: string;
  label?: string;
  icon?: IconType;
}

interface TabsProperties<T extends { [key: string]: Tab }> {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  tabs: T;
  isSealed?: boolean;
  small?: boolean;
  bottom?: boolean;
  full?: boolean;
}

const Tabs = <T extends { [key: string]: Tab }>({
  selectedTab,
  setSelectedTab,
  tabs,
  isSealed,
  small,
  full,
  bottom,
}: TabsProperties<T>) => {
  const { t } = useTranslation();
  return (
    <div
      className={`block w-full overflowx-auto ${isSealed ? 'lg:hidden' : ''}`}
    >
      <div className={`md:w-full`}>
        {bottom && <Separator />}
        <div
          className={`flex justify-center items-center lg:px-10 md:space-x-2 ${
            small ? 'space-x-1 px-1' : 'space-x-2 px-2'
          }`}
        >
          {Object.values(tabs).map((tab) => (
            <button
              key={tab.key}
              className={`${
                small ? 'px-1' : 'px-4'
              } border-4 ${bottom ? 'rounded-b-xl border-t-0' : 'rounded-t-xl border-b-0'} ${full && 'w-full'} py-2 font-chillaxBold text-sm lg:text-base border-darkBlue ${
                selectedTab === tab.key
                  ? 'bg-darkBlue text-white cursor-default'
                  : 'text-darkBlue hover:bg-lightBlue'
              }`}
              onClick={() => setSelectedTab(tab.key)}
            >
              {tab.label && t(tab.label)}
              {tab.icon && <tab.icon className="inline w-6 h-6" />}
            </button>
          ))}
        </div>
        {!bottom && <Separator />}
      </div>
    </div>
  );
};

export default Tabs;
