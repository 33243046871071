import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import { TABS_SHARED_CARDS } from '../constants/tabs';
import useFetchCards from '../hooks/useFetchCards';
import { useSharedCollection } from '../hooks/useSharedCollection';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import CompareModal from '../parts/modals/CompareModal';
import Tabs from '../parts/Tabs';
import { CardType } from '../types/card';
import { handleTextToCard } from '../utils/cardUtilities';

const SharedCollection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const binId = queryParameters.get('binId');
  const { getSharedCollection } = useSharedCollection();

  const [pseudo, setPseudo] = useState<string>();
  const [collectionCards, setCollectionCards] = useState<CardType[]>([]);
  const [missingCards, setMissingCards] = useState<CardType[]>([]);
  const [excessCards, setExcessCards] = useState<CardType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [isCompareModalOpen, setIsCompareModalOpen] = useState<boolean>(false);

  const handleOpenCompareModal = () => {
    setIsCompareModalOpen(true);
  };

  const handleCloseCompareModal = () => {
    setIsCompareModalOpen(false);
  };

  const { allCards } = useFetchCards(CARDS_PATH);

  useEffect(() => {
    const fetchSharedCollection = async (binId: string) => {
      setLoading(true);
      const response = await getSharedCollection(binId);
      if (response) {
        const myCardsText = response.cards || '';
        const missingCardsText = response.missingCards || '';
        const excessCardsText = response.excessCards || '';

        const parsedCollectionCards =
          allCards && handleTextToCard(myCardsText, allCards);
        const parsedMissingCards =
          allCards && handleTextToCard(missingCardsText, allCards);
        const parsedExcessCards =
          allCards && handleTextToCard(excessCardsText, allCards);

        parsedCollectionCards && setCollectionCards(parsedCollectionCards);
        parsedMissingCards && setMissingCards(parsedMissingCards);
        parsedExcessCards && setExcessCards(parsedExcessCards);
        setPseudo(response.pseudo || '');
      }
      setLoading(false);
    };
    if (
      collectionCards.length > 0 ||
      missingCards.length > 0 ||
      excessCards.length > 0
    ) {
      return;
    } else if (binId) fetchSharedCollection(binId);
  }, [
    binId,
    allCards,
    collectionCards,
    missingCards,
    excessCards,
    getSharedCollection,
  ]);

  const availableTabs: Partial<typeof TABS_SHARED_CARDS> = {};

  for (const [key, value] of Object.entries(TABS_SHARED_CARDS)) {
    if (
      (key === TABS_SHARED_CARDS.COLLECTION.key &&
        collectionCards.length > 0) ||
      (key === TABS_SHARED_CARDS.MISSING.key && missingCards.length > 0) ||
      (key === TABS_SHARED_CARDS.EXCESS.key && excessCards.length > 0)
    ) {
      availableTabs[key as keyof typeof TABS_SHARED_CARDS] = value;
    }
  }

  const firstAvailableTab = Object.keys(availableTabs)[0];
  if (!selectedTab && firstAvailableTab) {
    setSelectedTab(firstAvailableTab);
  }

  if (loading) {
    return <Loading label={t('loading.loadingCollection')} />;
  }

  return (
    <Layout>
      <div className="px-6 lg:px-20">
        {Object.keys(availableTabs).length > 0 ? (
          <>
            <div className="flex justify-center items-center space-x-4 mb-8">
              <h1 className="text-3xl font-chillaxBold text-center text-darkBlue">
                {t('shared.title', { PSEUDO: pseudo })}
              </h1>

              <Button onClick={handleOpenCompareModal}>
                {t('actions.compareToMyCollection')}
              </Button>
            </div>
            <Tabs
              tabs={availableTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              full
            />
            {selectedTab === TABS_SHARED_CARDS.COLLECTION.key && (
              <CardList cards={collectionCards} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_CARDS.MISSING.key && (
              <CardList cards={missingCards} showCollectionFilter />
            )}
            {selectedTab === TABS_SHARED_CARDS.EXCESS.key && (
              <CardList cards={excessCards} showCollectionFilter />
            )}
          </>
        ) : (
          <p className="text-center text-xl font-chillaxMedium">
            {t('errors.noSharedCollection')}
          </p>
        )}
        <CompareModal
          isOpen={isCompareModalOpen}
          onClose={handleCloseCompareModal}
          sharedMissingCards={missingCards}
          sharedExcessCards={excessCards}
        />
      </div>
    </Layout>
  );
};

export default SharedCollection;
