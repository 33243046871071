import React from 'react';

interface SeparatorProperties {
  desktop?: boolean;
}

const SeparatorVertical: React.FC<SeparatorProperties> = ({ desktop }) => {
  return (
    <div
      className={`border-l-4 border-darkBlue rounded w-1 h-full ${
        desktop ? 'hidden lg:block' : ''
      }`}
    />
  );
};

export default SeparatorVertical;
