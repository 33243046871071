import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import { useTeam } from '../../hooks/useTeam';
import { RootState } from '../../store/store';

interface JoinTeamModalProperties {
  isOpen: boolean;
  closeModal: () => void;
}

const JoinTeamModal: React.FC<JoinTeamModalProperties> = ({
  isOpen,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [teamNumber, setTeamNumber] = useState('');
  const [joinedSuccessfully, setJoinedSuccessfully] = useState(false);
  const { joinTeam, isLoading, error } = useTeam();

  const user = useSelector((state: RootState) => state.user);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const result = await joinTeam({ teamNumber, user });
    if (result) {
      setJoinedSuccessfully(true);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <div className="bg-beige p-8 space-y-8 rounded-lg rounded-t-none shadow-lg w-full max-w-md mx-auto border-4 border-t-0 border-darkBlue">
        <h2 className="text-xl font-chillaxBold mb-4">
          {t('actions.joinTeam')}
        </h2>
        {joinedSuccessfully ? (
          <p className="font-chillaxMedium">{t('teams.joinSuccess')}</p>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex items-end">
              <input
                type="text"
                id="createTeam"
                value={teamNumber}
                placeholder={t('teams.joinTeamPlaceholder')}
                onChange={(event) => setTeamNumber(event.target.value)}
                className="block w-full shadow sm:text-sm rounded-l-md rounded-r-none h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
              />
              <Button isSubmit right disabled={isLoading}>
                {t('actions.join')}
              </Button>
            </div>
          </form>
        )}
        {error && <p className="text-red-500 mt-2">{error}</p>}
        <Button onClick={closeModal} full>
          {t('actions.close')}
        </Button>
      </div>
    </Modal>
  );
};

export default JoinTeamModal;
