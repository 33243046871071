import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Card from '../../components/Card';
import ModalTemplate from '../../components/ModalTemplate';
import Separator from '../../components/Separator';
import { ACTIONS, RARITY } from '../../constants/constants';
import { CardType } from '../../types/card';
import { CardDeckType, DeckType } from '../../types/deck';
import { filterCardsByParticleAndFaction } from '../../utils/cardUtilities';

interface SelectRarityModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  handleCardAction: (cardId: string, action: string) => void;
  baseDeckCards: CardDeckType[];
  selectedParticuleID: string;
  allCards: CardType[];
  selectedAction: string;
  deck: DeckType;
}

const SelectRarityModal: React.FC<SelectRarityModalProperties> = ({
  isOpen,
  closeModal,
  handleCardAction,
  baseDeckCards,
  selectedParticuleID,
  selectedAction,
  allCards,
  deck,
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const matchedCards = useMemo(() => {
    return baseDeckCards.filter((deckCard) =>
      deckCard.reference.includes(selectedParticuleID)
    );
  }, [baseDeckCards, selectedParticuleID]);

  const allRarityCards = filterCardsByParticleAndFaction(
    allCards,
    selectedParticuleID,
    deck?.faction || ''
  );

  const filteredRarityCards = useMemo(() => {
    if (selectedAction === ACTIONS.ADD) {
      const uniqueCardReferences = new Set(
        baseDeckCards
          .filter((card) => card.rarity === RARITY.UNIQUE)
          .map((card) => card.reference)
      );

      return allRarityCards.filter(
        (card) =>
          card.rarity !== RARITY.UNIQUE ||
          !uniqueCardReferences.has(card.reference)
      );
    }
    return allRarityCards;
  }, [allRarityCards, baseDeckCards, selectedAction]);

  const cards =
    selectedAction === ACTIONS.ADD ? filteredRarityCards : matchedCards;

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    if (matchedCards.length === 1 && selectedAction === ACTIONS.REMOVE) {
      handleCardAction(matchedCards[0].reference, selectedAction);
    } else if (matchedCards.length > 1 || selectedAction === ACTIONS.ADD) {
      setShowModal(true);
    }
  }, [handleCardAction, matchedCards, selectedAction, isInitialRender]);

  const handleCardClick = (event: React.MouseEvent, cardReference: string) => {
    event.stopPropagation();
    handleCardAction(cardReference, selectedAction);
  };

  return (
    <>
      {showModal && (
        <ModalTemplate isOpen={isOpen} closeModal={closeModal}>
          <div className="flex flex-col space-y-4">
            <p className="font-chillaxBold text-xl text-center text-darkBlue">
              {selectedAction === ACTIONS.ADD
                ? t('deck.addCard')
                : t('deck.removeCard')}
            </p>
            <Separator />
            <div className="flex flex-wrap justify-center items-center gap-4">
              {cards.map((card) => (
                <div
                  key={card.reference}
                  className="w-52 md:w-40 cursor-pointer"
                  onClick={(event) => handleCardClick(event, card.reference)}
                >
                  <Card card={card} freeze noClick />
                </div>
              ))}
            </div>
          </div>
        </ModalTemplate>
      )}
    </>
  );
};

export default SelectRarityModal;
