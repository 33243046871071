import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import { TABS_DECKS_CHECKER } from '../constants/tabs';
import { useCardInput } from '../hooks/useCardInput';
import useFetchCards from '../hooks/useFetchCards';
import useMyCards from '../hooks/useMyCards';
import CardList from '../parts/CardList';
import DiscordTuto from '../parts/DiscordTuto';
import Layout from '../parts/Layout';
import CardSelectionModal from '../parts/modals/CardSelectionModal';
import Tabs from '../parts/Tabs';
import { RootState } from '../store/store';
import { CardStatsInfo, CardType } from '../types/card';
import { getMissingDeckCards } from '../utils/cardUtilities';
import { createMyCardsFromText } from '../utils/formatCards';

const DeckChecker = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deckCards, setDeckCards] = useState<CardType[]>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_DECKS_CHECKER.DECK.key
  );

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useFetchCards(CARDS_PATH, isFusionEnabled);

  const myCards = useMyCards();

  const missingCards =
    myCards && deckCards && getMissingDeckCards(myCards, deckCards);

  const {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  } = useCardInput();

  const handleCloseModal = () => {
    const selectedCardIds = selectedCards
      .map((card) => `${card.nbSelected || 1} ${card.reference}`)
      .join('\n');
    setCardInput(selectedCardIds);
    setModalIsOpen(false);
  };

  const handleCardInput = () => {
    const cardEntries = cardInput.split('\n');
    const cardData: CardStatsInfo[] = cardEntries.map((entry) => {
      const [count, reference] = entry.split(' ');
      return {
        reference,
        inMyCollection: Number.parseInt(count, 10) || 0,
      } as CardStatsInfo;
    });

    if (allCards) {
      const { includedCards } = createMyCardsFromText(allCards, cardData);
      if (includedCards.length > 0) {
        setDeckCards(includedCards);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(t('errors.invalidCardList'));
        setDeckCards([]);
      }
    }
  };

  document.title = t('navigation.decks') + ' | Altered Card';

  return (
    <Layout>
      <div className="space-y-10">
        <div className="flex justify-center items-center w-full">
          <div className="w-full items-start px-6 lg:px-20">
            <div className="flex flex-col md:flex-row space-x-2 justify-center items-center">
              <div className="space-y-2">
                <Button onClick={() => setModalIsOpen(true)} full>
                  {t('actions.selectCards')}
                </Button>
              </div>
              <span className="font-chillaxBold text-darkBlue">
                {t('connecter.or')}
              </span>
              <div className="flex justify-center w-full">
                <textarea
                  rows={4}
                  placeholder={t('actions.enterYourDeckList')}
                  value={cardInput}
                  onChange={handleCardInputChange}
                  className="block w-full shadow sm:text-sm p-1 rounded-l-md rounded-r-none border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
                />
                <Button onClick={handleCardInput} isVertical right>
                  {t('actions.validate')}
                </Button>
              </div>
            </div>
            <DiscordTuto />
            {/* Error Message */}
            {errorMessage && (
              <div className="flex justify-center">
                <span className="text-red-600">{errorMessage}</span>
              </div>
            )}
          </div>
        </div>
        {deckCards && (
          <Tabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={TABS_DECKS_CHECKER}
            full
          />
        )}
        {deckCards && deckCards.length > 0 && (
          <>
            {selectedTab === TABS_DECKS_CHECKER.DECK.key && (
              <CardList cards={deckCards} />
            )}
            {selectedTab === TABS_DECKS_CHECKER.MISSING.key && missingCards && (
              <CardList cards={missingCards} />
            )}
          </>
        )}
      </div>

      <CardSelectionModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        cards={allCards || []}
        selectedCards={selectedCards}
        setSelectedCards={setSelectedCards}
        myCollection={myCards}
        quantity
      />
    </Layout>
  );
};

export default DeckChecker;
