import React from 'react';

import { AxisLeft } from '@visx/axis';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive'; // Import de ParentSize pour la responsivité
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStackHorizontal } from '@visx/shape';
import { SeriesPoint } from '@visx/shape/lib/types';
import { Tooltip, defaultStyles, withTooltip } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { t } from 'i18next';

import Separator from './Separator';
import { CardDeckType } from '../types/deck';

type BiomeType = 'FOREST_POWER' | 'MOUNTAIN_POWER' | 'OCEAN_POWER';

type TooltipData = {
  bar: SeriesPoint<{
    cost: string;
    FOREST_POWER: number;
    MOUNTAIN_POWER: number;
    OCEAN_POWER: number;
    total: number;
  }>;
  key: BiomeType;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

type BarStackHorizontalProperties = {
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  data: CardDeckType[];
  isLegendSmall?: boolean;
};

const forestColor = '#9fb143';
const mountainColor = '#ba8046';
const oceanColor = '#566ea1';

const defaultMargin = { top: 0, left: 20, right: 0, bottom: 0 };
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  fontFamily: 'Chillax-Semibold',
  backgroundColor: '#1E2036',
  color: 'white',
  padding: '10px',
};

type LegendProperties = {
  isLegendSmall?: boolean;
  items: {
    total: number;
    label: string;
    color: string;
    iconSrc: string;
  }[];
};

type LegendItemProperties = {
  total: number;
  label: string;
  color: string;
  iconSrc: string;
  isSmall?: boolean;
};

const LegendItem: React.FC<LegendItemProperties> = ({
  total,
  label,
  color,
  iconSrc,
  isSmall,
}) => (
  <div className="flex w-fit py-2 space-x-2">
    <img src={iconSrc} alt={`${label} icon`} className="w-8" />
    <div
      className={`flex flex-col ${isSmall ? 'justify-center' : 'items-start'} `}
      style={{ color }}
    >
      <h2 className="font-chillaxBold text-base">{total}</h2>
      {!isSmall && (
        <h3 className="font-chillaxMedium text-xs md:text-sm">{label}</h3>
      )}
    </div>
  </div>
);

const Legend: React.FC<LegendProperties> = ({ items, isLegendSmall }) => (
  <div className="flex space-x-4">
    {items.map((item, index) => (
      <LegendItem
        key={index}
        total={item.total}
        label={item.label}
        color={item.color}
        iconSrc={item.iconSrc}
        isSmall={isLegendSmall}
      />
    ))}
  </div>
);

const parseBiomeValue = (value: string | undefined): number => {
  const match = value?.match(/\d+/);
  return match ? Number.parseInt(match[0], 10) : 0;
};

const transformCardData = (data: CardDeckType[]) => {
  const manaCosts = data
    .flatMap((card) => parseBiomeValue(card.elements?.MAIN_COST))
    .filter((cost) => !Number.isNaN(cost));

  const minCost = Math.min(...manaCosts);
  const maxCost = Math.max(...manaCosts);

  const transformed = Array.from(
    { length: maxCost - minCost + 1 },
    (_, index) => {
      const cost = minCost + index;
      const forestPower = data.reduce(
        (sum, card) =>
          sum +
          (parseBiomeValue(card.elements?.MAIN_COST) === cost
            ? parseBiomeValue(card.elements?.FOREST_POWER) * card.quantity
            : 0),
        0
      );
      const mountainPower = data.reduce(
        (sum, card) =>
          sum +
          (parseBiomeValue(card.elements?.MAIN_COST) === cost
            ? parseBiomeValue(card.elements?.MOUNTAIN_POWER) * card.quantity
            : 0),
        0
      );
      const oceanPower = data.reduce(
        (sum, card) =>
          sum +
          (parseBiomeValue(card.elements?.MAIN_COST) === cost
            ? parseBiomeValue(card.elements?.OCEAN_POWER) * card.quantity
            : 0),
        0
      );

      return {
        cost: cost.toString(),
        FOREST_POWER: forestPower,
        MOUNTAIN_POWER: mountainPower,
        OCEAN_POWER: oceanPower,
        total: forestPower + mountainPower + oceanPower,
      };
    }
  ).filter((d) => d.total > 0);

  const totalForest = transformed.reduce((sum, d) => sum + d.FOREST_POWER, 0);
  const totalMountain = transformed.reduce(
    (sum, d) => sum + d.MOUNTAIN_POWER,
    0
  );
  const totalOcean = transformed.reduce((sum, d) => sum + d.OCEAN_POWER, 0);

  const maxTotalBiome = Math.max(...transformed.map((d) => d.total));

  return {
    transformed,
    maxTotalBiome,
    totalForest,
    totalMountain,
    totalOcean,
  };
};

const colorScale = scaleOrdinal<BiomeType, string>({
  domain: ['FOREST_POWER', 'MOUNTAIN_POWER', 'OCEAN_POWER'],
  range: [forestColor, mountainColor, oceanColor],
});

export default withTooltip<BarStackHorizontalProperties, TooltipData>(
  ({
    margin = defaultMargin,
    data,
    isLegendSmall,
    events = false,
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  }: BarStackHorizontalProperties & WithTooltipProvidedProps<TooltipData>) => {
    const {
      transformed: chartData,
      maxTotalBiome,
      totalForest,
      totalMountain,
      totalOcean,
    } = transformCardData(data);

    const biomeLabels = {
      FOREST_POWER: t('biomes.forest'),
      MOUNTAIN_POWER: t('biomes.mountain'),
      OCEAN_POWER: t('biomes.ocean'),
    };

    const biomeLegendItems = [
      {
        total: totalForest,
        label: biomeLabels.FOREST_POWER,
        color: '#9fb143',
        iconSrc: '/assets/biomes/forest.svg',
      },
      {
        total: totalMountain,
        label: biomeLabels.MOUNTAIN_POWER,
        color: '#ba8046',
        iconSrc: '/assets/biomes/mountain.svg',
      },
      {
        total: totalOcean,
        label: biomeLabels.OCEAN_POWER,
        color: '#566ea1',
        iconSrc: '/assets/biomes/ocean.svg',
      },
    ];

    return (
      <ParentSize>
        {({ width, height }) => {
          const legendHeight = 100;
          const svgHeight = height - legendHeight;

          const xMax = width;
          const yMax = svgHeight;

          const manaScale = scaleBand<string>({
            domain: chartData.map((d) => d.cost),
            padding: 0.2,
          }).rangeRound([0, yMax]);

          const quantityScale = scaleLinear<number>({
            domain: [0, maxTotalBiome],
            nice: true,
          }).rangeRound([0, xMax - 50]);

          let tooltipTimeout: number;

          return (
            <div className="p-2 w-full h-full border-4 rounded border-darkBlue bg-lightBlue space-y-2">
              <svg width="100%" height={svgHeight}>
                <rect
                  width="100%"
                  height={svgHeight}
                  fill={'transparent'}
                  rx={14}
                />
                <Group top={margin.top} left={margin.left}>
                  <BarStackHorizontal
                    data={chartData}
                    keys={['FOREST_POWER', 'MOUNTAIN_POWER', 'OCEAN_POWER']}
                    height={yMax}
                    y={(d) => d.cost}
                    xScale={quantityScale}
                    yScale={manaScale}
                    color={colorScale}
                  >
                    {(barStacks) =>
                      barStacks.map((barStack) =>
                        barStack.bars.map((bar) => (
                          <g
                            key={`barstack-horizontal-${barStack.index}-${bar.index}`}
                          >
                            {/* Rectangle pour la bordure */}
                            <rect
                              x={bar.x + 1}
                              y={bar.y}
                              width={bar.width}
                              height={bar.height}
                              stroke="#1E2036"
                              strokeWidth={4}
                              rx={1}
                            />
                            {/* Rectangle pour la barre */}
                            <rect
                              x={bar.x + 1}
                              y={bar.y}
                              width={bar.width}
                              height={bar.height}
                              fill={bar.color}
                              rx={1}
                              onClick={() => {
                                if (events)
                                  alert(`clicked: ${JSON.stringify(bar)}`);
                              }}
                              onMouseLeave={() => {
                                tooltipTimeout = globalThis.setTimeout(() => {
                                  hideTooltip();
                                }, 300) as unknown as number;
                              }}
                              onMouseMove={() => {
                                if (tooltipTimeout)
                                  clearTimeout(tooltipTimeout);
                                const top = bar.y + margin.top;
                                const left = bar.x + bar.width + margin.left;
                                showTooltip({
                                  tooltipData: bar,
                                  tooltipTop: top,
                                  tooltipLeft: left,
                                });
                              }}
                            />
                          </g>
                        ))
                      )
                    }
                  </BarStackHorizontal>

                  <AxisLeft
                    hideTicks
                    scale={manaScale}
                    tickLabelProps={{
                      fontSize: 16,
                      fontFamily: 'Chillax-Bold',
                      textAnchor: 'middle',
                      fill: '#1E2036',
                      dy: '0.33em',
                    }}
                    stroke="#1E2036"
                    strokeWidth={3}
                  />
                </Group>
              </svg>

              {tooltipOpen && tooltipData && (
                <Tooltip
                  top={tooltipTop}
                  left={tooltipLeft}
                  style={tooltipStyles}
                >
                  <div style={{ color: colorScale(tooltipData.key) }}>
                    <strong>{`${tooltipData.bar.data[tooltipData.key]} ${biomeLabels[tooltipData.key]}`}</strong>
                  </div>
                  <div>
                    <small>
                      {t('deck.manaCost', { cost: tooltipData.bar.data.cost })}
                    </small>
                  </div>
                </Tooltip>
              )}
              <Separator />
              <Legend items={biomeLegendItems} isLegendSmall={isLegendSmall} />
            </div>
          );
        }}
      </ParentSize>
    );
  }
);
