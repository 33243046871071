import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CardType } from '../../types/card';

interface CollectionCardsState {
  cards: CardType[];
  favoriteCards: CardType[];
}

const initialState: CollectionCardsState = {
  cards: [],
  favoriteCards: [],
};

const collectionCardsSlice = createSlice({
  name: 'collectionCards',
  initialState,
  reducers: {
    setCollectionCards(state, action: PayloadAction<CardType[]>) {
      state.cards = action.payload;
    },
    addFavoriteCard(state, action: PayloadAction<CardType>) {
      state.favoriteCards = state.favoriteCards
        ? [...state.favoriteCards, action.payload]
        : [action.payload];
    },
    removeFavoriteCard(state, action: PayloadAction<CardType>) {
      const cardToRemove = action.payload;
      state.favoriteCards = state.favoriteCards.filter(
        (favoriteCard) => favoriteCard.reference !== cardToRemove.reference
      );
    },
  },
});

export const { setCollectionCards, addFavoriteCard, removeFavoriteCard } =
  collectionCardsSlice.actions;

export default collectionCardsSlice.reducer;
