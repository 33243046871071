import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Team } from '../../types/team';
import { User } from '../../types/user';

const initialState: User = {
  _id: '',
  email: '',
  pseudo: '',
  betaTester: false,
  teams: [],
  accessToken: '',
  refreshToken: '',
  cardsCollection: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      return action.payload;
    },
    setTeams(state, action: PayloadAction<Team[]>) {
      state.teams = action.payload;
    },
    clearUser() {
      return initialState;
    },
  },
});

export const { setUser, setTeams, clearUser } = userSlice.actions;
export default userSlice.reducer;
