import { useDispatch } from 'react-redux';

import { clearUser } from '../store/slices/userSlice';
import { AppDispatch } from '../store/store';

export const useLogout = () => {
  const dispatch: AppDispatch = useDispatch();

  const logout = (): void => {
    dispatch(clearUser());
  };

  return { logout };
};
