import React from 'react';

import Modal from '@mui/material/Modal';

import { CardType } from '../../types/card';
import CardList from '../CardList';

interface CardSelectionModalProperties {
  isOpen: boolean;
  onClose: () => void;
  cards: CardType[];
  selectedCards: CardType[];
  setSelectedCards: (cards: CardType[]) => void;
  myCollection?: CardType[];
  quantity?: boolean;
  faction?: string;
}

const CardSelectionModal: React.FC<CardSelectionModalProperties> = ({
  isOpen,
  onClose,
  cards,
  selectedCards,
  setSelectedCards,
  quantity = false,
  faction,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige p-4 rounded shadow-lg lg:w-2/3 h-5/6 space-y-4 overflow-x-auto relative border-4 border-darkBlue">
        <CardList
          cards={cards}
          setSelectedCards={setSelectedCards}
          handleClose={onClose}
          selectedCards={selectedCards}
          quantity={quantity}
          faction={faction}
          isExchange
          isClose
        />
      </div>
    </Modal>
  );
};

export default CardSelectionModal;
