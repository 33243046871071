import React from 'react';

import DeckCardActions from './DeckCardActions';
import HorizontalCardDisplay from './HorizontalCardDisplay';
import { DeckCardsType } from '../../types/deck';

interface DeckCardListProperties {
  cards: DeckCardsType[];
  asFavorite?: boolean;
  isCreater?: boolean;
  addDeckParticulId: (particleId: string) => void;
  removeDeckParticulId: (particleId: string) => void;
  changeRarity: (particleId: string) => void;
}

const DeckHorizontalCardList: React.FC<DeckCardListProperties> = ({
  cards,
  isCreater,
  addDeckParticulId,
  removeDeckParticulId,
  changeRarity,
}) => {
  return (
    <div className="container mx-auto p-4 space-y-4">
      <div className="flex flex-wrap justify-center items-start w-full gap-4">
        {cards.map((deck) => {
          return (
            <div key={deck.particleId} className="w-96">
              {isCreater && (
                <DeckCardActions
                  particleId={deck.particleId}
                  addDeckParticulId={addDeckParticulId}
                  removeDeckParticulId={removeDeckParticulId}
                  changeRarity={changeRarity}
                />
              )}

              {deck.cards.map((card, index) => (
                <HorizontalCardDisplay
                  key={card.reference}
                  card={card}
                  isCreater={isCreater}
                  quantity={card.quantity}
                  isLast={index === deck.cards.length - 1}
                />
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeckHorizontalCardList;
