import React from 'react';

import { t } from 'i18next';

import Block from '../../components/Block';
import Button from '../../components/Button';
import { CardDeckType } from '../../types/deck';
import { calculateSummary } from '../../utils/cardUtilities';

interface DeckSummaryProperties {
  cards: CardDeckType[];
  small?: boolean;
  handleToggleStatsPanel?: () => void;
}

const flexRarity = (summaryNb: number, rarity: string) => {
  return (
    <div className="flex flex-col items-center w-fit space-y-2">
      <span className="text-xl">{summaryNb}</span>
      <img
        src={`/assets/rarity/${rarity}.png`}
        alt={rarity + 'banner'}
        className="h-3 lg:h-5 object-cover"
      />
    </div>
  );
};

const DeckSummary: React.FC<DeckSummaryProperties> = ({
  cards,
  small,
  handleToggleStatsPanel,
}) => {
  const summary = calculateSummary(cards);

  return (
    <div className="grid auto-rows-min space-y-2">
      <div className="flex items-end space-x-2">
        <div className="flex flex-col justify-center space-y-2">
          <div className="flex items-center space-x-2">
            <Block
              number={summary.total}
              label="gameElements.cards"
              isFit={small}
            />
            {!small && <div className="h-1 min-w-3 rounded bg-darkBlue" />}
            <Block
              number={summary.characters}
              label="gameElements.characters"
              isFit={small}
            />
            <Block
              number={summary.spells}
              label="gameElements.spells"
              isFit={small}
            />
            <Block
              number={summary.permanents}
              label="gameElements.permanents"
              isFit={small}
            />
            <div className="hidden md:flex">
              <div className="flex rounded shadow-md justify-center items-center p-2 h-fit min-w-40 border-4 border-darkBlue bg-white">
                <div className="flex space-x-1 items-end font-chillaxMedium">
                  {flexRarity(summary.common, 'COMMON')}
                  {flexRarity(summary.rare, 'RARE')}
                  {flexRarity(summary.unique, 'UNIQUE')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:hidden space-x-2">
        <div className="flex rounded shadow-md justify-center p-2 h-fit w-full  space-x-1 items-end font-chillaxMedium border-4 border-darkBlue bg-white">
          {flexRarity(summary.common, 'COMMON')}
          {flexRarity(summary.rare, 'RARE')}
          {flexRarity(summary.unique, 'UNIQUE')}
        </div>
        <Button onClick={handleToggleStatsPanel}>
          {t('actions.advancedStats')}
        </Button>
      </div>
    </div>
  );
};

export default DeckSummary;
