import React from 'react';

interface PaginationProperties {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProperties> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const getPageNumbers = () => {
    const pageNumbers: (number | string)[] = [];

    if (totalPages <= 5) {
      // Si le total de pages est de 5 ou moins, afficher toutes les pages
      for (let index = 1; index <= totalPages; index++) {
        pageNumbers.push(index);
      }
    } else {
      // Logique de pagination avec plus de 5 pages
      pageNumbers.push(1);

      if (currentPage > 4) {
        // Ajouter "..." si la page courante est après la page 4
        pageNumbers.push('');
      }

      if (currentPage <= 4) {
        // Si la page courante est entre 1 et 4, afficher les pages 2 à 5
        for (let index = 2; index <= 5; index++) {
          pageNumbers.push(index);
        }
      } else if (currentPage > 4 && currentPage < totalPages - 3) {
        // Si on est au milieu de la pagination, afficher les pages autour de la page courante
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1);
      } else {
        // Si la page courante est proche de la fin, afficher les dernières pages
        for (let index = totalPages - 4; index < totalPages; index++) {
          pageNumbers.push(index);
        }
      }

      if (currentPage < totalPages - 3) {
        // Ajouter "..." si la page courante est loin des dernières pages
        pageNumbers.push('');
      }

      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <div className=" absolute -top-7 flex justify-between items-center">
      <div className="flex space-x-2 font-chillaxBold w-full">
        {pageNumbers.map((page, index) =>
          page === '' ? (
            <span
              key={index}
              className="flex justify-center items-center w-8 h-8"
            >
              ...
            </span>
          ) : (
            <button
              key={index}
              onClick={() => onPageChange(page as number)}
              className={`w-fit px-2 h-8 rounded border-4 border-darkBlue ${
                currentPage === page
                  ? 'bg-darkBlue text-lightBlue'
                  : 'bg-lightBlue text-darkBlue'
              }`}
            >
              {page}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default Pagination;
