import {
  CARD_TYPE,
  FACTIONS,
  NUMBER_FILTERS,
  RARITY,
} from '../constants/constants';

export const collectionFilterModes = [
  { value: NUMBER_FILTERS.EQUALS, label: '=' },
  { value: NUMBER_FILTERS.GREATER, label: '>' },
  { value: NUMBER_FILTERS.LESS, label: '<' },
];

export const cardsTypeData = [
  { value: CARD_TYPE.HERO, label: 'gameElements.heroes' },
  { value: CARD_TYPE.CHARACTER, label: 'gameElements.characters' },
  { value: CARD_TYPE.SPELL, label: 'gameElements.spells' },
  { value: CARD_TYPE.PERMANENT, label: 'gameElements.permanents' },
  { value: CARD_TYPE.TOKEN, label: 'gameElements.token' },
  { value: CARD_TYPE.TOKEN_MANA, label: 'gameElements.tokenMana' },
];

export const cardsTypeDeckData = [
  { value: CARD_TYPE.CHARACTER, label: 'gameElements.characters' },
  { value: CARD_TYPE.SPELL, label: 'gameElements.spells' },
  { value: CARD_TYPE.PERMANENT, label: 'gameElements.permanents' },
];

export const cardsFactionData = [
  { value: FACTIONS.YZ, label: 'factions.yzmir' },
  { value: FACTIONS.AX, label: 'factions.axiom' },
  { value: FACTIONS.BR, label: 'factions.bravos' },
  { value: FACTIONS.MU, label: 'factions.muna' },
  { value: FACTIONS.LY, label: 'factions.lyra' },
  { value: FACTIONS.OR, label: 'factions.ordis' },
];

export const cardsRarityData = [
  { value: RARITY.COMMON, label: 'gameElements.commons' },
  { value: RARITY.RARE, label: 'gameElements.rares' },
  { value: RARITY.UNIQUE, label: 'gameElements.unique' },
];
