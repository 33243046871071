import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserFriendship } from '../../types/card';

interface FriendsState {
  friends: UserFriendship[];
}

const initialState: FriendsState = {
  friends: [],
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setFriends(state, action: PayloadAction<UserFriendship[]>) {
      state.friends = action.payload;
    },
    updateFriend(state, action: PayloadAction<UserFriendship>) {
      const index = state.friends.findIndex(
        (friend) => friend.friendshipId === action.payload.friendshipId
      );
      if (index !== -1) {
        state.friends[index] = action.payload;
      }
    },
  },
});

export const { setFriends, updateFriend } = friendsSlice.actions;

export default friendsSlice.reducer;
