import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../components/Button';
import WhiteContainer from '../components/WhiteContainer';
import { TABS_DECKS } from '../constants/tabs';
import { useDeck } from '../hooks/useDeck';
import DeckPresentation from '../parts/decks/DeckPresentation';
import DiscordTuto from '../parts/DiscordTuto';
import Layout from '../parts/Layout';
import CreateDeckModal from '../parts/modals/CreateDeckModal';
import Tabs from '../parts/Tabs';
import { RootState } from '../store/store';
import { DeckType } from '../types/deck';

const Decks = () => {
  const { t } = useTranslation();
  const [decks, setDecks] = useState<DeckType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [publicDecks, setPublicDecks] = useState<DeckType[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(
    TABS_DECKS.MY_DECKS.key
  );
  const user = useSelector((state: RootState) => state.user);
  const { getUserDecks, getPublicDecks } = useDeck();

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const createNewDeck = () => {
    setModalIsOpen(true);
  };

  const fetchDecks = async () => {
    setIsLoading(true);
    const decks = await getUserDecks(user);
    if (decks) {
      setDecks(decks);
    } else {
      setSelectedTab(TABS_DECKS.PUBLIC_DECKS.key);
    }

    const publicDecks = await getPublicDecks();
    if (publicDecks) setPublicDecks(publicDecks);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) fetchDecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  document.title = t('navigation.decks') + ' | Altered Card';

  return (
    <Layout>
      <div className="space-y-10">
        <div className="flex justify-center items-center w-full">
          <div className="w-full items-start px-6 lg:px-20">
            <div className="flex flex-col md:flex-row space-x-2 justify-center items-center">
              <Button onClick={createNewDeck}>{t('actions.createDeck')}</Button>
            </div>
            <DiscordTuto />
          </div>
        </div>
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={TABS_DECKS}
          full
        />
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-full">
            <h3 className="font-chillaxBold text-darkBlue">
              {t('loading.loadingDecks')}
            </h3>
          </div>
        ) : (
          <>
            {selectedTab === TABS_DECKS.MY_DECKS.key && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {decks.map((deck, index) => (
                  <div key={`privatedeck-${index}`}>
                    <DeckPresentation
                      baseDeck={deck}
                      refreshDecks={fetchDecks}
                    />
                  </div>
                ))}
              </div>
            )}
            {selectedTab === TABS_DECKS.PUBLIC_DECKS.key && (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {publicDecks.map((deck, index) => (
                  <div key={`publicdeck-${index}`}>
                    <WhiteContainer key={`publicdeck-${index}`}>
                      <DeckPresentation baseDeck={deck} publicDeck />
                    </WhiteContainer>
                  </div>
                ))}
              </div>
            )}
            {selectedTab === TABS_DECKS.MY_TEAM_DECKS.key && (
              <div className="flex justify-center items-center font-chillaxBold text-red-600">
                {t('errors.featureSoonAvailable')}
              </div>
            )}
          </>
        )}
      </div>

      {modalIsOpen && (
        <CreateDeckModal isOpen={modalIsOpen} closeModal={handleCloseModal} />
      )}
    </Layout>
  );
};

export default Decks;
