import axios from 'axios';

import { CardStatsInfo, CardType, TradeListCardAPI } from '../types/card';
import { handleError } from '../utils/errorHandler';
import {
  formatCardsFriend,
  formatUniqueCardsFriend,
} from '../utils/formatCards';

interface FetchFriendsCollectionCardsResult {
  uniqueCards: CardType[];
  transformedCards: CardStatsInfo[];
}

export const fetchFriendTradingCard = async (
  token: string,
  userId: string,
  setErrorMessage: (error: string) => void
): Promise<FetchFriendsCollectionCardsResult | undefined> => {
  try {
    const response = await axios.get(
      'https://api.altered.gg/ownership_lists/tradelist/users/' + userId,
      {
        params: {
          itemsPerPage: 500,
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'fr-fr',
        },
      }
    );
    const transformedCards: CardStatsInfo[] = formatCardsFriend(
      response.data['hydra:member'] as TradeListCardAPI[]
    );
    const uniqueCards: CardType[] = formatUniqueCardsFriend(
      response.data['hydra:member'],
      userId
    );

    return { transformedCards, uniqueCards };
  } catch (error) {
    console.error('Error fetching cards:', error);
    handleError(error, setErrorMessage);
    return undefined;
  }
};
