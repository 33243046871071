import React from 'react';

interface SeparatorProperties {
  desktop?: boolean;
  color?: string;
}

const Separator: React.FC<SeparatorProperties> = ({ desktop, color }) => {
  return (
    <div
      className={`border-b-4 ${color || 'border-darkBlue'} rounded ${
        desktop ? 'hidden lg:block' : ''
      }`}
    />
  );
};

export default Separator;
