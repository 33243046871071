import React from 'react';

import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import LanguageSelector from './LanguageSelector';
import NavLink from '../components/NavLink';
import { LANGUAGES } from '../constants/constants';
import { RootState } from '../store/store';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const NavBar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const user = useSelector((state: RootState) => state.user);

  const handleGoToProfil = () => {
    navigate(getNavigateUrl(i18n.language, 'profil'));
  };

  return (
    <nav className="w-full z-50 fixed hidden md:block">
      <div className="relative shadow-xl px-4 py-4 flex justify-between items-center bg-darkBlue text-white z-50">
        <div className="flex w-full justify-center items-center font-chillaxBold lg:text-xl">
          <div className="flex justify-center items-center w-1/2 space-x-16">
            <NavLink
              to={getNavigateUrl(i18n.language, 'cards')}
              label={t('navigation.cards')}
            />
            <NavLink
              to={getNavigateUrl(i18n.language, 'decks')}
              label={t('navigation.decks')}
            />
            <NavLink
              to={getNavigateUrl(i18n.language, 'teams')}
              label={t('navigation.teams')}
            />
          </div>
          <div className="flex justify-center items-center w-1/2 space-x-8">
            <NavLink
              to={getNavigateUrl(i18n.language, 'tools')}
              label={t('navigation.tools')}
            />
            <NavLink
              to={getNavigateUrl(i18n.language, 'market')}
              label={t('navigation.market')}
            />
          </div>
        </div>
        <Link
          to={`${currentLanguage}/`}
          className="flex flex-col items-center absolute -bottom-8 right-1/2 w-fit"
          style={{
            transform: `translate(50%)`,
          }}
        >
          <span className="flex w-fit justify-center pb-4 px-10 text-xl font-bold font-chillaxBold uppercase whitespace-nowrap rounded-b-3xl shadow-2xl bg-darkBlue">
            <img
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="Altered Sealed Logo"
              className="h-10 w-auto object-contain transform transition-transform duration-200 hover:scale-105"
              height={40}
              width={40}
            />
          </span>
        </Link>
        <div className="absolute right-3 flex space-x-2 justify-center items-center">
          <div
            className="flex space-x-2 items-center cursor-pointer"
            onClick={handleGoToProfil}
          >
            <p className="font-chillaxBold">
              {user?.pseudo || t('navigation.login')}
            </p>
            <FaUser />
          </div>

          <span>/</span>
          <LanguageSelector />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
