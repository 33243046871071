import { useState } from 'react';

import { useDispatch } from 'react-redux';

import api from '../api/axiosInstance';
import { setUser } from '../store/slices/userSlice';
import { AppDispatch } from '../store/store';
import { User } from '../types/user';
import { handleError } from '../utils/errorHandler';

interface RegisterProperties {
  email: string;
  pseudo: string;
  password: string;
  closeModal: () => void;
}

export const useRegister = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const register = async ({
    email,
    pseudo,
    password,
    closeModal,
  }: RegisterProperties): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.post('/user/register', {
        email,
        pseudo,
        password,
      });

      if (response.status === 200) {
        const userResponse: User = response.data;

        // Mise à jour de l'utilisateur dans le Redux store
        dispatch(setUser(userResponse));

        setIsLoading(false);
        closeModal();
      } else {
        setError("Erreur lors de l'inscription");
        setIsLoading(false);
      }
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
    }
  };

  return { register, isLoading, error };
};
