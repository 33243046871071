import { useEffect, useState } from 'react';

import { CARD_TYPE, RARITY, VERSION } from '../constants/constants';
import { CardType, SortedCard } from '../types/card';
import { filterByVersion } from '../utils/cardUtilities';

const dispatchCards = (cardsSorted: CardType[]) => {
  function getRandomUniqueCards(cardsSorted: CardType[], numberOfCards = 100) {
    // Filtrer les cartes pour ne garder que celles avec une rareté UNIQUE
    const uniqueCards = cardsSorted.filter(
      (card) => card.rarity === RARITY.UNIQUE
    );

    // Mélanger les cartes uniques
    const shuffledUniqueCards = uniqueCards.sort(() => 0.5 - Math.random());

    // Sélectionner les 'numberOfCards' premières cartes après mélange
    return shuffledUniqueCards.slice(0, numberOfCards);
  }

  const heroCards = cardsSorted.filter((card) => card.type === CARD_TYPE.HERO);
  const characterCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.CHARACTER
  );
  const spellCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.SPELL
  );
  const permanentCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.PERMANENT
  );
  const tokenCards = cardsSorted.filter(
    (card) => card.type === CARD_TYPE.TOKEN
  );

  const commonCards = cardsSorted.filter(
    (card) => card.rarity === RARITY.COMMON && card.type !== CARD_TYPE.HERO
  );
  const rareCards = cardsSorted.filter((card) => card.rarity === RARITY.RARE);
  const uniqueCards = getRandomUniqueCards(cardsSorted, 100);

  return {
    heroCards,
    characterCards,
    spellCards,
    permanentCards,
    tokenCards,
    commonCards,
    rareCards,
    uniqueCards,
  };
};

const useFetchCards = (
  cardDataPath: string,
  isFusionEnabled?: boolean,
  version?: string
) => {
  const [allCards, setAllCards] = useState<CardType[]>();
  const [sortedCards, setSortedCards] = useState<SortedCard>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();

  const fetchCards = async () => {
    try {
      const response = await fetch(cardDataPath);
      if (!response.ok) {
        throw new Error('Failed to fetch cards data');
      }

      const cardData = await response.json();
      const items = Object.values(cardData) as CardType[];

      if (isFusionEnabled) {
        setAllCards(filterByVersion(items, VERSION.DEFAULT));
      } else if (version) {
        setAllCards(filterByVersion(items, version));
      } else {
        setAllCards(items);
      }
    } catch {
      setError('Error fetching data');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFusionEnabled, version]);

  useEffect(() => {
    if (allCards) {
      setSortedCards(dispatchCards(allCards));
    }
  }, [allCards]);

  return { allCards, sortedCards, loading, error };
};

export default useFetchCards;
