import React, { useState } from 'react';

import { t } from 'i18next';

import { CardType } from '../types/card';
import { ExportOption } from '../types/card';
import ExportModal from './modals/ExportModal';
import Button from '../components/Button';

export interface ExportCardsProperties {
  cards: CardType[];
  exportOptions: ExportOption[];
  exportToolsOptions: ExportOption[];
  isSealed?: boolean;
  full?: boolean;
}

const ExportCards: React.FC<ExportCardsProperties> = ({
  cards,
  exportOptions,
  exportToolsOptions,
  isSealed,
  full,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div>
      <Button full={full} onClick={openModal}>
        {t('actions.export')}
      </Button>
      <ExportModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        cards={cards}
        exportOptions={exportOptions}
        exportToolsOptions={exportToolsOptions}
        isSealed={isSealed}
      />
    </div>
  );
};

export default ExportCards;
