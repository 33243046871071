import React from 'react';

import { Link, useLocation } from 'react-router-dom';

const NavLink: React.FC<{ to: string; label: string }> = ({ to, label }) => {
  const location = useLocation();

  const isActive = location.pathname.includes(to);

  return (
    <Link
      to={to}
      className={`whitespace-nowrap ${
        isActive
          ? 'text-[#b3ecff] cursor-default'
          : 'hover:text-beige hover:underline'
      }`}
    >
      {label}
    </Link>
  );
};

export default NavLink;
