import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { fetchPublicUniqueCards } from '../api/fetchUniqueCards';
import { ACTIONS, CARDS_PATH, RARITY } from '../constants/constants';
import { useDeck } from '../hooks/useDeck';
import useFetchCards from '../hooks/useFetchCards';
import useMyCards from '../hooks/useMyCards';
import i18n from '../i18n';
import { RootState } from '../store/store';
import { CardStatsInfo, CardType } from '../types/card';
import { CardDeckType, DeckCardsType, DeckType } from '../types/deck';
import { getCardByReference } from '../utils/cardUtilities';
import { createDeckCards } from '../utils/deckUtilities';
import { createDeckCardsFromText } from '../utils/formatCards';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const useDeckBuilder = () => {
  const { t } = useTranslation();
  const { deckId } = useParams();
  const navigate = useNavigate();

  const myCards = useMyCards(true);
  const { allCards } = useFetchCards(CARDS_PATH, true);
  const user = useSelector((state: RootState) => state.user);
  const { getDeckDetail, getPublicDeckDetail, updateDeck } = useDeck();

  const [deck, setDeck] = useState<DeckType>();
  const [deckCards, setDeckCards] = useState<DeckCardsType[]>([]);
  const [baseDeckCards, setBaseDeckCards] = useState<CardDeckType[]>([]);
  const [allCardsWithUniques, setAllCardsWithUniques] = useState<CardType[]>(
    []
  );

  const [isDeckLoaded, setIsDeckLoaded] = useState(false);

  const [selectedAction, setSelectedAction] = useState<string>(ACTIONS.ADD);
  const [selectedParticuleID, setSelectedParticuleID] = useState<string>('');

  const [cardsModalIsOpen, setCardsModalIsOpen] = useState(false);
  const [rarityModalIsOpen, setRarityModalIsOpen] = useState<boolean>(false);
  const [changeRarityModalIsOpen, setChangeRarityModalIsOpen] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const isCreater = user?._id === deck?.createdBy;

  const goBackToDecks = () => navigate(getNavigateUrl(i18n.language, 'decks'));

  const handleSaveDeck = async () => {
    if (deckId && baseDeckCards) {
      updateDeck({ deckId, deckCards: baseDeckCards, user });
    }
  };

  const tryLoadUnique = async (
    uniqueCards: CardStatsInfo[],
    includedCards: CardType[]
  ): Promise<CardDeckType[]> => {
    // TODO : Afficher une modale avec les privateUniqueCardStats qui ne peuvent pas être chargées
    const { publicUniqueCards, privateUniqueCardStats } =
      await fetchPublicUniqueCards(uniqueCards);
    if (privateUniqueCardStats.length > 0) {
      setErrorMessage(
        t('errors.privateUniqueCards', { count: privateUniqueCardStats.length })
      );
    }
    return [...includedCards, ...publicUniqueCards] as CardDeckType[];
  };

  const handleTextToBaseDeckCard = async (cardInput: string) => {
    const cardEntries = cardInput.split('\n');
    const cardData: CardStatsInfo[] = cardEntries.map((entry) => {
      const [count, reference] = entry.split(' ');
      return {
        reference,
        quantity: Number.parseInt(count, 10) || 0,
      } as CardStatsInfo;
    });

    if (allCardsWithUniques) {
      const { includedCards, excludedUniqueCards } = createDeckCardsFromText(
        allCardsWithUniques,
        cardData,
        myCards
      );
      if (excludedUniqueCards.length > 0) {
        const temporary: CardDeckType[] = await tryLoadUnique(
          excludedUniqueCards,
          includedCards
        );
        setBaseDeckCards(temporary);
      } else if (includedCards.length > 0) {
        setBaseDeckCards(includedCards);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(t('errors.invalidCardList'));
        setDeckCards([]);
      }
    }
  };

  const handleCloseModal = (cardInput: string) => {
    handleTextToBaseDeckCard(cardInput);
    setCardsModalIsOpen(false);
  };

  function changeCardRarity(particleId: string) {
    setSelectedParticuleID(particleId);
    setChangeRarityModalIsOpen(true);
  }

  function addDeckParticleId(particleId: string) {
    setSelectedAction(ACTIONS.ADD);
    setRarityModalIsOpen(true);
    setSelectedParticuleID(particleId);
  }

  function removeDeckParticleId(particleId: string) {
    setSelectedAction(ACTIONS.REMOVE);
    setRarityModalIsOpen(true);
    setSelectedParticuleID(particleId);
  }

  const handleCardAction = (cardId: string, action: string) => {
    setBaseDeckCards((previousBaseDeckCards) => {
      let cardFound = false;

      const updatedBaseDeckCards = previousBaseDeckCards
        .map((deckCard) => {
          if (deckCard.reference === cardId) {
            cardFound = true;
            if (action === ACTIONS.ADD) {
              return {
                ...deckCard,
                quantity: deckCard.quantity + 1,
              };
            } else if (action === ACTIONS.REMOVE && deckCard.quantity > 0) {
              return {
                ...deckCard,
                quantity: deckCard.quantity - 1,
              };
            }
          }
          return deckCard;
        })
        .filter((deckCard) => deckCard.quantity > 0);

      if (!cardFound && action === ACTIONS.ADD) {
        const newcard = getCardByReference(cardId, allCardsWithUniques);
        if (newcard) {
          updatedBaseDeckCards.push({
            ...newcard,
            quantity: 1,
          });
        }
      }

      return updatedBaseDeckCards;
    });

    setRarityModalIsOpen(false);
  };

  function updateDeckState(deck: DeckType) {
    setDeck(deck);
    setBaseDeckCards(deck?.cards || []);
    setIsDeckLoaded(true);
    if (deck?.cards?.length === 0) {
      setCardsModalIsOpen(true);
    }
  }

  useEffect(() => {
    if (allCards && myCards) {
      setAllCardsWithUniques([
        ...allCards,
        ...myCards.filter((card) => card.rarity === RARITY.UNIQUE),
      ]);
    } else if (allCards) {
      setAllCardsWithUniques(allCards);
    }
  }, [allCards, myCards]);

  useEffect(() => {
    if (baseDeckCards.length > 0) {
      setDeckCards(createDeckCards(baseDeckCards));
    }
  }, [baseDeckCards]);

  useEffect(() => {
    const fetchDecks = async (deckId: string) => {
      const deck = await getDeckDetail({ user, deckId });
      const deckPublic = await getPublicDeckDetail({ deckId });
      if (deck) {
        updateDeckState(deck);
      } else if (deckPublic) {
        updateDeckState(deckPublic);
      } else {
        goBackToDecks();
      }
    };
    if (deckId) {
      fetchDecks(deckId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isDeckLoaded,
    isCreater,
    deck,
    baseDeckCards,
    deckCards,
    selectedAction,
    selectedParticuleID,
    allCardsWithUniques,
    errorMessage,
    cardsModalIsOpen,
    rarityModalIsOpen,
    changeRarityModalIsOpen,
    setBaseDeckCards,
    handleCardAction,
    handleCloseModal,
    handleTextToBaseDeckCard,
    setChangeRarityModalIsOpen,
    handleSaveDeck,
    setRarityModalIsOpen,
    addDeckParticleId,
    removeDeckParticleId,
    updateDeckState,
    changeCardRarity,
    setCardsModalIsOpen,
  };
};

export default useDeckBuilder;
