import { useEffect, useState } from 'react';

import useFetchCards from './useFetchCards';
import { CARDS_PATH } from '../constants/constants';
import { CardType } from '../types/card';

export const useCardInput = () => {
  const [cardInput, setCardInput] = useState('');
  const [selectedCards, setSelectedCards] = useState<CardType[]>([]);
  const { allCards } = useFetchCards(CARDS_PATH);

  const handleCardInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setCardInput(event.target.value);
  };

  useEffect(() => {
    const cardEntries = cardInput.split('\n');
    const updatedSelectedCards: CardType[] = [];

    for (const entry of cardEntries) {
      const [count, id] = entry.split(' ');
      const card = allCards?.find((card) => card.reference === id);
      if (card && !Number.isNaN(Number.parseInt(count, 10))) {
        updatedSelectedCards.push({
          ...card,
          nbSelected: Number.parseInt(count, 10),
        });
      }
    }

    setSelectedCards(updatedSelectedCards);
  }, [cardInput, allCards]);

  return {
    cardInput,
    selectedCards,
    handleCardInputChange,
    setSelectedCards,
    setCardInput,
  };
};
