import React from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import DynamicSortButtons from './DynamicSortButtons';
import { FRIEND_SORT_MODE, SORT_DIRECTION } from '../constants/constants';

interface FriendFilterModalProperties {
  modalIsOpen: boolean;
  closeModal: () => void;
  sortMode: string;
  setSortMode: (mode: string) => void;
  sortDirection: string;
  setSortDirection: (direction: string) => void;
}

const FriendFilterModal: React.FC<FriendFilterModalProperties> = ({
  modalIsOpen,
  closeModal,
  sortMode,
  setSortMode,
  sortDirection,
  setSortDirection,
}) => {
  const { t } = useTranslation();

  const toggleSortDirection = () => {
    setSortDirection(
      sortDirection === SORT_DIRECTION.ASCENDING
        ? SORT_DIRECTION.DESCENDING
        : SORT_DIRECTION.ASCENDING
    );
  };

  const primarySortOptions = [
    {
      mode: FRIEND_SORT_MODE.BY_TRADE_CARDS,
      label: t('friendship.filter.tradeCards'),
      showIcon: true,
    },
    {
      mode: FRIEND_SORT_MODE.BY_LAST_UPDATE,
      label: t('friendship.filter.lastUpdate'),
      showIcon: true,
    },
    {
      mode: FRIEND_SORT_MODE.BY_TOTAL_TRADES,
      label: t('friendship.filter.totalTrades'),
      showIcon: true,
    },
  ];

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <div
        className={
          'p-4 rounded shadow-lg max-w-lg w-full relative font-chillaxMedium space-y-4 border-4 border-darkBlue bg-beige'
        }
      >
        <button
          onClick={closeModal}
          className="absolute text-2xl font-bold top-3 right-3 text-darkBlue hover:text-gray-800 cursor-pointer"
        >
          ✕
        </button>
        <DynamicSortButtons
          sortMode={sortMode}
          setSortMode={setSortMode}
          sortDirection={sortDirection}
          toggleSortDirection={toggleSortDirection}
          options={primarySortOptions}
        />
      </div>
    </Modal>
  );
};

export default FriendFilterModal;
