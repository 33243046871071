import { useState } from 'react';

import api from '../api/axiosInstance';
import { CardType } from '../types/card';
import { User } from '../types/user';
import { handleError } from '../utils/errorHandler';

export const useCollection = () => {
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Mise à jour de la collection de cartes
  const updateCollection = async (
    collection: CardType[],
    user: User
  ): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      await api.post(
        '/user/cardsCollection',
        { collection },
        {
          headers: {
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      setIsLoading(false);
    } catch (error) {
      handleError(error, setError);

      setIsLoading(false);
    }
  };

  // Récupération de la collection de cartes
  const getCollection = async (user: User): Promise<CardType[] | undefined> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await api.get('/user/cardsCollection', {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });

      setIsLoading(false);
      return response.data.cards as CardType[];
    } catch (error) {
      handleError(error, setError);
      setIsLoading(false);
      return undefined;
    }
  };

  return { updateCollection, getCollection, isLoading, error };
};
