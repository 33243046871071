import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

import Button from '../../components/Button';
import Card from '../../components/Card';
import Separator from '../../components/Separator';
import WhiteContainer from '../../components/WhiteContainer';
import { CardType } from '../../types/card';
import { CardDeckType, DeckType } from '../../types/deck';
import { filterCardsByParticleAndFaction } from '../../utils/cardUtilities';

interface ChangeRarityModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  selectedParticuleID: string;
  baseDeckCards: CardDeckType[];
  setBaseDeckCards: (value: CardDeckType[]) => void;
  allCards: CardType[];
  deck: DeckType;
}

const ChangeRarityModal: React.FC<ChangeRarityModalProperties> = ({
  isOpen,
  closeModal,
  selectedParticuleID,
  baseDeckCards,
  setBaseDeckCards,
  allCards,
  deck,
}) => {
  const { t } = useTranslation();
  const [selectedChanges, setSelectedChanges] = useState<{
    [key: string]: CardType;
  }>({});

  const matchedCards = baseDeckCards.filter((deckCard) =>
    deckCard.reference.includes(selectedParticuleID)
  );

  const allRarityCards = filterCardsByParticleAndFaction(
    allCards,
    selectedParticuleID,
    deck?.faction || ''
  );

  const matchedUniqueReferences = new Set(
    matchedCards
      .filter((card) => card.rarity === 'UNIQUE')
      .map((card) => card.reference)
  );

  const possibleChanges = matchedCards.flatMap((matchedCard) => {
    return allRarityCards
      .filter(
        (rarityCard) =>
          rarityCard.rarity !== matchedCard.rarity &&
          (rarityCard.rarity !== 'UNIQUE' ||
            !matchedUniqueReferences.has(rarityCard.reference))
      )
      .map((rarityCard) => ({
        from: matchedCard,
        to: rarityCard,
      }));
  });

  const toggleSelection = (fromReference: string, toCard: CardType) => {
    setSelectedChanges((previous) => {
      const updated = { ...previous };
      if (
        updated[fromReference] &&
        updated[fromReference].reference === toCard.reference
      ) {
        delete updated[fromReference];
      } else {
        updated[fromReference] = toCard;
      }
      return updated;
    });
  };

  const applyChanges = () => {
    let updatedDeckCards = [...baseDeckCards];

    for (const card of baseDeckCards) {
      if (selectedChanges[card.reference]) {
        const updatedCard = selectedChanges[card.reference];

        if (
          updatedCard.rarity === 'UNIQUE' &&
          card.quantity &&
          card.quantity > 1
        ) {
          updatedDeckCards = updatedDeckCards.map((deckCard) => {
            if (deckCard.reference === card.reference) {
              return {
                ...deckCard,
                quantity: deckCard.quantity - 1,
              };
            }
            return deckCard;
          });

          updatedDeckCards.push({
            ...updatedCard,
            quantity: 1,
          });
        } else {
          updatedDeckCards = updatedDeckCards.map((deckCard) => {
            if (deckCard.reference === card.reference) {
              return {
                ...updatedCard,
                quantity: card.quantity || 1,
              };
            }
            return deckCard;
          });
        }
      }
    }

    const mergedDeckCards: CardDeckType[] = [];
    for (const card of updatedDeckCards) {
      const existingCardIndex = mergedDeckCards.findIndex(
        (existingCard) => existingCard.reference === card.reference
      );
      if (existingCardIndex === -1) {
        mergedDeckCards.push(card);
      } else {
        mergedDeckCards[existingCardIndex].quantity += card.quantity;
      }
    }

    setBaseDeckCards(mergedDeckCards);
    closeModal();
  };

  if (!isOpen) return <></>;

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <div className="bg-beige p-2 md:p-8 max-h-screen rounded-lg shadow-lg w-fit mx-auto border-4 border-t-0 border-darkBlue">
        <div className="space-y-4">
          <h2 className="text-xl text-darkBlue font-chillaxBold mb-4">
            {t('Quel changements souhaites-tu faire ?')}
          </h2>
          <Separator />

          <div className="flex flex-col space-y-4 max-h-56 md:max-h-96 overflow-x-auto pb-8">
            {possibleChanges.map(({ from, to }, index) => (
              <div key={`${from.reference}-${to.reference}-${index}`}>
                <WhiteContainer
                  isSelected={
                    selectedChanges[from.reference]?.reference === to.reference
                  }
                >
                  <div
                    key={`${from.reference}-${to.reference}-${index}`}
                    className={`flex items-center p-4 rounded border-darkBlue w-54 md:w-96 cursor-pointer`}
                    onClick={() => toggleSelection(from.reference, to)}
                  >
                    <Card card={from} freeze={true} noClick={true} />
                    <div className="mx-4 text-2xl">→</div>
                    <Card card={to} freeze={true} noClick={true} />
                  </div>
                </WhiteContainer>
              </div>
            ))}
          </div>
        </div>
        <div className="space-y-4">
          <Separator />

          <Button
            disabled={Object.keys(selectedChanges).length === 0}
            onClick={applyChanges}
            full
          >
            {t('Confirmer les changements')}
          </Button>
          <Button onClick={() => closeModal()} full>
            {t('actions.close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChangeRarityModal;
