import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../components/Button';
import { CARDS_PATH, LANGUAGES, VISIBILITY } from '../../constants/constants';
import { HERO } from '../../constants/decks';
import { useDeck } from '../../hooks/useDeck';
import useFetchCards from '../../hooks/useFetchCards';
import i18n from '../../i18n';
import { RootState } from '../../store/store';
import { CardType } from '../../types/card';
import { DeckType } from '../../types/deck';
import { getCardByReference } from '../../utils/cardUtilities';

interface ModifyDeckModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  deck: DeckType;
  updateDeckState: (deck: DeckType) => void;
}

const ModifyDeckModal: React.FC<ModifyDeckModalProperties> = ({
  isOpen,
  closeModal,
  deck,
  updateDeckState,
}) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;

  const [deckName, setDeckName] = useState<string>(deck.name || '');
  const [deckHero, setDeckHero] = useState<CardType>(deck.hero || '');
  const [isPublic, setIsPublic] = useState<boolean>(deck.isPublic);
  const { updateDeck, isLoading } = useDeck();
  const { allCards } = useFetchCards(CARDS_PATH, true);

  const user = useSelector((state: RootState) => state.user);

  const handleHeroChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedHero =
      allCards && getCardByReference(event.target.value, allCards);

    if (selectedHero) {
      setDeckHero(selectedHero);
    }
  };

  const handleTeamChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    if (selectedValue === VISIBILITY.PRIVATE) {
      setIsPublic(false);
    } else if (selectedValue === VISIBILITY.PUBLIC) {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const updatedDeck = await updateDeck({
      user,
      deckName,
      deckHero,
      isPublic: isPublic,
      deckId: deck._id,
      deckCards: deck.cards,
    });

    if (updatedDeck) {
      updateDeckState(updatedDeck);
      closeModal();
    }
  };

  if (!isOpen) return <></>;

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-75 z-[1000] bg-black"
    >
      <div className="bg-beige p-8 space-y-8 rounded-lg rounded-t-none shadow-lg w-full max-w-md mx-auto border-4 border-t-0 border-darkBlue">
        <h2 className="text-xl font-chillaxBold mb-4">
          {t('actions.modifyDeck')}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-4">
            <input
              type="text"
              id="createDeckName"
              value={deckName}
              placeholder={t('deck.createDeckPlaceholder')}
              onChange={(event) => setDeckName(event.target.value)}
              className="block w-full shadow sm:text-sm rounded-md h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue"
            />

            <select
              id="createDeckHero"
              value={deckHero.name[currentLanguage]}
              onChange={handleHeroChange}
              className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
            >
              <option value="" disabled>
                {t('deck.selectHeroPlaceholder')}
              </option>
              {Object.values(HERO)
                .filter((hero) => hero.faction && hero.faction === deck.faction)
                .map((hero) => (
                  <option key={hero.key} value={hero.key}>
                    {t(hero.label)}
                  </option>
                ))}
            </select>

            <select
              id="createDeckTeam"
              value={isPublic ? VISIBILITY.PUBLIC : VISIBILITY.PRIVATE}
              onChange={handleTeamChange}
              className="block w-full shadow sm:text-sm rounded-md h-12 pl-4 border-4 font-chillaxRegular cursor-pointer outline-none bg-lightBlue border-darkBlue"
            >
              <option value={VISIBILITY.PRIVATE}>{t('deck.private')}</option>
              <option value={VISIBILITY.PUBLIC}>{t('deck.public')}</option>
            </select>

            <Button isSubmit disabled={isLoading}>
              {t('actions.modify')}
            </Button>
          </div>
        </form>
        {/* {error && <p className="text-red-500 mt-2">{error}</p>} */}
        <Button onClick={closeModal} full>
          {t('actions.close')}
        </Button>
      </div>
    </Modal>
  );
};

export default ModifyDeckModal;
