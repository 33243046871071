import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationDE from './locales/de.json';
import translationEN from './locales/en.json';
import translationES from './locales/es.json';
import translationFR from './locales/fr.json';
import translationIT from './locales/it.json';
import CustomLanguageDetector from './utils/customLanguageDetector';

i18n
  .use(CustomLanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      fr: {
        translation: translationFR,
      },
      es: {
        translation: translationES,
      },
      it: {
        translation: translationIT,
      },
      de: {
        translation: translationDE,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    react: {
      useSuspense: false,
    },
  });

export { default } from 'i18next';
