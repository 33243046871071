import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '../components/Button';
import { CARDS_PATH } from '../constants/constants';
import useFetchCards from '../hooks/useFetchCards';
import { useTeam } from '../hooks/useTeam';
import BackButton from '../parts/BackButton';
import CardList from '../parts/CardList';
import Layout from '../parts/Layout';
import Loading from '../parts/Loading';
import MembersModal from '../parts/modals/MembersModal';
import Tabs, { Tab } from '../parts/Tabs';
import { RootState } from '../store/store';
import { CardType } from '../types/card';
import { Team } from '../types/team';
import { User } from '../types/user';
import { fusionSets } from '../utils/cardUtilities';
import { getNavigateUrl } from '../utils/getNavigateUrl';

const TeamDetailPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { teamId } = useParams<{ teamId: string }>();
  const { leaveTeam, deleteTeam, getTeamDetails, isLoading, error } = useTeam();
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [isMembersModalOpen, setIsMembersModalOpen] = useState<boolean>(false);
  const [team, setTeam] = useState<Team>();
  const navigate = useNavigate();
  const [selectedMember, setSelectedMember] = useState<User>();
  const [selectedMemberCards, setSelectedMemberCards] = useState<CardType[]>();

  const user = useSelector((state: RootState) => state.user);

  const isFusionEnabled = useSelector(
    (state: RootState) => state.sidePanel.isFusionEnabled
  );

  const { allCards } = useFetchCards(CARDS_PATH, isFusionEnabled);

  useEffect(() => {
    const getTeam = async (teamId: string) => {
      const teamTemporary = await getTeamDetails({ teamId, user });
      if (teamTemporary) {
        setTeam(teamTemporary);
        if (teamTemporary.members.length > 0) {
          setSelectedTab(teamTemporary.members[0]._id);
        }
      }
    };
    if (teamId && !team) getTeam(teamId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, team]);

  useEffect(() => {
    if (team) {
      setSelectedMember(
        team.members.find((member: User) => member._id === selectedTab)
      );
      if (selectedMember && isFusionEnabled && allCards) {
        const ksCards = selectedMember.cardsCollection.filter((card) =>
          card.reference.includes('COREKS')
        );
        const defaultCards = selectedMember.cardsCollection.filter((card) =>
          card.reference.includes('CORE_')
        );
        setSelectedMemberCards(fusionSets(ksCards, defaultCards, allCards));
      } else {
        setSelectedMemberCards(selectedMember?.cardsCollection);
      }
    }
  }, [selectedTab, isFusionEnabled, allCards, selectedMember, team]);

  const tabs: { [key: string]: Tab } = {};

  if (team) {
    for (const member of team.members) {
      tabs[member._id] = { key: member._id, label: member.pseudo };
    }
  } else {
    return <Loading label={t('loading.loading')} />;
  }

  document.title = t('navigation.teams') + `-${team?.name} | Altered Card`;

  const goBackToTeams = () => {
    navigate(getNavigateUrl(i18n.language, 'teams'));
  };

  const handleLeave = async () => {
    const reponse = await leaveTeam({ teamId: team._id, user });
    if (reponse) goBackToTeams();
  };

  const handleDelete = async () => {
    const reponse = await deleteTeam({ teamId: team._id, user });
    if (reponse) goBackToTeams();
  };

  if (isLoading) {
    return <Loading label={t('loading.loading')} />;
  }

  if (!user?._id) {
    goBackToTeams();
  }

  return (
    <Layout>
      <div className="container mx-auto p-4">
        {error && <p className="text-red-500">{error}</p>}
        {team && (
          <div className="space-y-16">
            <div className="space-y-4">
              <BackButton to="teams" title={team.name} />
              <div className="flex w-full h-fit space-x-2">
                <Button full onClick={() => setIsMembersModalOpen(true)}>
                  {t('actions.members')}
                </Button>
                <Button full onClick={handleLeave}>
                  {t('actions.leaveTeam')}
                </Button>
                {user._id === team.admin && (
                  <Button full onClick={handleDelete}>
                    {t('actions.removeTeam')}
                  </Button>
                )}
              </div>
            </div>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={tabs}
              full
            />
            {selectedMemberCards && (
              <CardList
                cards={selectedMemberCards}
                showCollectionFilter
                isExport
              />
            )}
          </div>
        )}
      </div>
      <MembersModal
        isOpen={isMembersModalOpen}
        closeModal={() => setIsMembersModalOpen(false)}
        team={team}
      />
    </Layout>
  );
};

export default TeamDetailPage;
