interface ElementSpanProperties {
  number: string;
  color: string;
}

const ElementSpan = ({ number, color }: ElementSpanProperties) => {
  const isElementHighlighted = number.startsWith('#') && number.endsWith('#');

  const formattedElement = isElementHighlighted ? number.slice(1, -1) : number;

  return (
    <span
      className={`flex flex-col justify-center ${color} w-fit px-2 rounded shadow-md font-chillaxMedium ${
        isElementHighlighted ? 'text-yellowRare' : 'text-white'
      }`}
    >
      <p className="font-light font lg:text-sm text-xs lowercase">
        {formattedElement}
      </p>
    </span>
  );
};

export default ElementSpan;
