import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Card from './Card';
import { LANGUAGES } from '../constants/constants';
import { CardType } from '../types/card';

const randomRotation = () => `${Math.floor(Math.random() * 30) - 10}deg`;

interface CardSliderProperties {
  cards: CardType[];
}

type PrintCardType = CardType & { rotateAngle: string };

const CardSlider: React.FC<CardSliderProperties> = ({ cards }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language || LANGUAGES.FRENCH;
  const [printCards, setPrintCards] = useState<PrintCardType[]>(
    cards.map((card) => ({
      ...card,
      rotateAngle: randomRotation(),
    }))
  );

  useEffect(() => {
    const lcpImage = cards[0]?.imagePath;
    if (lcpImage) {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.href = lcpImage[currentLanguage];
      link.as = 'image';
      document.head.append(link);
    }
  }, [cards, currentLanguage]);

  const handleNext = () => {
    setPrintCards((previousCards) => {
      const [firstCard, ...rest] = previousCards;
      return [...rest, { ...firstCard, rotateAngle: randomRotation() }];
    });
  };

  const bringToFront = (index: number) => {
    if (index === 0) {
      handleNext();
      return;
    }
    setPrintCards((previousCards) => {
      const selectedCard = previousCards[index];
      const otherCards = previousCards.filter((_, index_) => index_ !== index);
      const [firstCard, ...remainingCards] = otherCards;

      return [
        { ...selectedCard },
        ...remainingCards,
        { ...firstCard, rotateAngle: randomRotation() },
      ];
    });
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div className="relative w-full h-full">
        {printCards.map((card, index) => (
          <div
            key={card.reference}
            onClick={() => bringToFront(index)}
            className={`absolute inset-0 m-auto transition-transform duration-500 ease-in-out transform w-fit h-fit cursor-pointer`}
            style={{
              transform: `rotate(${card.rotateAngle}) translateX(${
                index === 0 ? '-10%' : '0%'
              })`,
              opacity: 1,
              zIndex: printCards.length - index,
            }}
          >
            <div className="w-52 md:w-72">
              <Card card={card} noClick />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSlider;
