//import axios from 'axios';

/**
 * Gère les erreurs en fonction de leur type (AxiosError ou autre).
 * @param error - L'erreur à traiter.
 * @param setErrorMessage - Fonction pour mettre à jour le message d'erreur.
 */

// interface ErrorData {
//   code: number;
//   message: string;
// }

export const handleError = (
  error: unknown,
  setErrorMessage?: (message: string) => void
): void => {
  setErrorMessage && setErrorMessage('An error occurred');
  /*if (setErrorMessage) {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data || 'An unknown error occurred';
      setErrorMessage(errorMessage);
    } else if (error instanceof Error) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage('An unexpected error occurred');
    }
  } else {
    console.error('Error:', error);
  }*/
};
