import React, { useEffect, useRef, useState } from 'react';

import { FaHeart } from 'react-icons/fa';
import { FaRegHeart } from 'react-icons/fa';

interface NameFilterInputProperties {
  nameFilter: string;
  setNameFilter: (value: string) => void;
  smallFilter: boolean;
  placeholder: string;
}

const NameFilterInput: React.FC<NameFilterInputProperties> = ({
  nameFilter,
  setNameFilter,
  smallFilter,
  placeholder,
}) => {
  const [previousValues, setPreviousValues] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const savedValues = JSON.parse(
      localStorage.getItem('nameFilterHistory') || '[]'
    );
    setPreviousValues(savedValues);

    setIsSaved(savedValues.includes(nameFilter));
  }, [nameFilter]);

  const toggleSaveValue = () => {
    if (isSaved) {
      const updatedValues = previousValues.filter(
        (value) => value !== nameFilter
      );
      setPreviousValues(updatedValues);
      localStorage.setItem('nameFilterHistory', JSON.stringify(updatedValues));
      setIsSaved(false);
    } else if (nameFilter.trim() !== '') {
      const updatedValues = [nameFilter, ...previousValues].slice(0, 5);
      setPreviousValues(updatedValues);
      localStorage.setItem('nameFilterHistory', JSON.stringify(updatedValues));
      setIsSaved(true);
    }
  };

  const removeValue = (value: string) => {
    const updatedValues = previousValues.filter((v) => v !== value);
    setPreviousValues(updatedValues);
    localStorage.setItem('nameFilterHistory', JSON.stringify(updatedValues));
  };

  const handleSuggestionClick = (suggestion: string) => {
    setNameFilter(suggestion);
    setShowSuggestions(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value);
    if (event.target.value.trim() === '') {
      setShowSuggestions(false);
    } else {
      setShowSuggestions(true);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200); // Délai pour permettre l'interaction avec la liste
  };

  return (
    <div className="w-full relative">
      <div className="relative w-full">
        <input
          ref={inputReference}
          type="text"
          id="nameFilter"
          placeholder={placeholder}
          value={nameFilter}
          onChange={handleInputChange}
          onFocus={() => setShowSuggestions(true)}
          onBlur={handleBlur}
          className={`block w-full max-w-36 shadow sm:text-sm ${
            smallFilter
              ? 'rounded-md max-w-52'
              : 'rounded-l-md rounded-r-none max-w-36'
          } h-9 p-5 border-4 font-chillaxRegular outline-none bg-lightBlue border-darkBlue`}
        />

        <button
          type="button"
          onClick={toggleSaveValue}
          className={`absolute top-1/2 right-2 transform -translate-y-1/2 ${
            isSaved ? 'text-red-500' : 'text-darkBlue'
          }`}
        >
          {isSaved ? <FaHeart /> : <FaRegHeart />}
        </button>
      </div>

      {showSuggestions && previousValues.length > 0 && (
        <ul className="absolute bg-white border border-darkBlue w-full max-w-36 z-10 mt-1">
          {previousValues.map((value, index) => (
            <li
              key={index}
              className="p-2 flex justify-between items-center hover:bg-lightBlue"
            >
              <span
                className="cursor-pointer"
                onMouseDown={() => handleSuggestionClick(value)}
              >
                {value}
              </span>
              <button
                type="button"
                onMouseDown={() => removeValue(value)}
                className="text-red-500 ml-2 cursor-pointer"
              >
                ×
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NameFilterInput;
