import i18n from 'i18next';
import { toast } from 'react-toastify';

import { downloadFile } from './fileUtils';
import {
  EXPORT_CARD_FORMAT,
  EXPORT_FORMAT,
  FACTIONS_NAMES,
} from '../constants/constants';
import { CardType, ExportHandlerParameters } from '../types/card';
import { CardDeckType } from '../types/deck';

type generateExportProperties = {
  cards: CardType[] | CardDeckType[];
  lineSeparator: string;
  needFilling?: boolean;
  cardFormat: typeof EXPORT_CARD_FORMAT.ID | typeof EXPORT_CARD_FORMAT.NAME;
};

export type ExportHandler = (parameters: ExportHandlerParameters) => void;

const fillerCardReference = 'ALT_CORE_B_NE_1_C';

// Génération du texte d'exportation du deck
export const generateExportDeckText = ({
  cards,
  lineSeparator = '\n',
  needFilling,
  cardFormat = EXPORT_CARD_FORMAT.ID,
}: generateExportProperties): string => {
  const cardMap: Record<string, { card: CardType; quantity: number }> = {};

  // Compter les cartes par référence et cumuler les quantités
  for (const card of cards) {
    const reference = card.reference;
    const quantity =
      'quantity' in card ? card.quantity : card.inMyCollection || 1;

    if (cardMap[reference]) {
      cardMap[reference].quantity += quantity;
    } else {
      cardMap[reference] = { card, quantity };
    }
  }

  // Calculer le nombre total de cartes dans le deck
  const totalCards = Object.values(cardMap).reduce(
    (sum, { quantity }) => sum + quantity,
    0
  );

  // Générer le texte du deck exporté
  let exportDeckText = Object.values(cardMap)
    .map(({ card, quantity }) => {
      const cardIdentifier =
        cardFormat === EXPORT_CARD_FORMAT.ID
          ? card.reference
          : `${card.name[i18n.language]} | ${FACTIONS_NAMES[card.mainFaction]}`;
      return `${quantity} ${cardIdentifier}`;
    })
    .join(lineSeparator);

  // Si needFilling est activé, compléter pour avoir 30 cartes au total
  if (needFilling && totalCards < 30) {
    const fillerCount = 30 - totalCards;
    for (let index = 0; index < fillerCount; index++) {
      exportDeckText += `${lineSeparator}1 ${fillerCardReference}`;
    }
  }

  return exportDeckText;
};

const formatExportText = (text: string, exportFormat: string): string => {
  if (exportFormat === EXPORT_FORMAT.BASE64) {
    return btoa(text); // Convertir en Base64
  } else if (exportFormat === EXPORT_FORMAT.CSV) {
    return convertToCSV(text); // Convertir en CSV
  }
  return text; // Retourner le texte normal si aucun format spécifique
};

const convertToCSV = (text: string): string => {
  return text
    .split('\n')
    .map((line) => line.split(' ').join(','))
    .join('\n');
};

// Exportation du deck sous forme de fichier texte
export const handleExportIDs: ExportHandler = ({
  cards,
  needFilling,
  cardFormat = EXPORT_CARD_FORMAT.ID,
  exportFormat = EXPORT_FORMAT.NORMAL,
}) => {
  let exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '\n',
    needFilling,
    cardFormat,
  });

  exportDeckText = formatExportText(exportDeckText, exportFormat);

  downloadFile(
    cardFormat === EXPORT_CARD_FORMAT.ID ? 'card_ids.txt' : 'card_names.txt',
    exportDeckText
  );
};

// Copier le texte du deck dans le presse-papiers
export const handleCopyToClipboard: ExportHandler = ({
  cards,
  needFilling,
  label,
  cardFormat = EXPORT_CARD_FORMAT.ID,
  exportFormat = EXPORT_FORMAT.NORMAL,
}) => {
  let exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '\n',
    needFilling,
    cardFormat,
  });

  exportDeckText = formatExportText(exportDeckText, exportFormat);

  navigator.clipboard
    .writeText(exportDeckText)
    .then(() => label && toast.success(label))
    .catch(() => toast.error('Failed to copy to clipboard'));
};

// Exporter vers Exalts Table
export const handleExportToExaltsTable: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '%0A',
    needFilling,
    cardFormat: EXPORT_CARD_FORMAT.ID,
  });
  const url = `https://exalts-table.com/deck-test/link/?decklist=${exportDeckText}`;
  window.open(url, '_blank');
};

// Exporter vers Altered DB
export const handleExportToAlteredDB: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '%0A',
    needFilling,
    cardFormat: EXPORT_CARD_FORMAT.ID,
  });
  const url = `https://altered-db.com/?d=${exportDeckText}`;
  window.open(url, '_blank');
};

// Exporter vers l'outil d'échange
export const handleExportToExchangeTool: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '%0A',
    needFilling,
    cardFormat: EXPORT_CARD_FORMAT.ID,
  });
  const url = `https://www.altered-card.com/exchange?list=${exportDeckText}`;
  window.open(url, '_blank');
};

// Exporter vers le simulateur de première main
export const handleSimulatorFirstHand: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '%0A',
    needFilling,
    cardFormat: EXPORT_CARD_FORMAT.ID,
  });
  const url = `https://altered-db.com/fhand/?cardslist=${exportDeckText}`;
  window.open(url, '_blank');
};

// Exporter vers Altered TGC Builder
export const handleAlteredTgcBuilder: ExportHandler = ({
  cards,
  needFilling,
}) => {
  const exportDeckText = generateExportDeckText({
    cards,
    lineSeparator: '\n',
    needFilling,
    cardFormat: EXPORT_CARD_FORMAT.ID,
  });
  const encodedDeck = encodeURIComponent(exportDeckText);
  const url = `https://altered.ajordat.com/fr/decks/new/?decklist=${encodedDeck}`;

  window.open(url, '_blank');
};
