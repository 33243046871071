import React, { useState } from 'react';

import Modal from '@mui/material/Modal';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../components/Button';

interface UniqueCardsModalProperties {
  isOpen: boolean;
  closeModal: () => void;
  loadWithUniques: (token: string) => void;
  loadWithoutUniques: () => void;
}

const UniqueCardsModal: React.FC<UniqueCardsModalProperties> = ({
  isOpen,
  closeModal,
  loadWithUniques,
  loadWithoutUniques,
}) => {
  const { t } = useTranslation();
  const [token, setToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleLoadWithUniques = () => {
    if (token) {
      setErrorMessage('');
      loadWithUniques(token);
    } else {
      setErrorMessage(t('errors.noEnterToken'));
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-75 z-[1000]"
    >
      <div className="bg-beige p-6 rounded shadow-lg w-full max-w-xl max-h-full border-4 space-y-6 overflow-x-auto border-darkBlue text-darkBlue">
        <div className="space-y-2">
          <h2 className="text-lg font-chillaxBold font-bold text-darkBlue">
            {t('uniqueModal.title')}
          </h2>
          <p className="font-chillaxMedium">
            <Trans i18nKey="uniqueModal.desc" />
          </p>

          {errorMessage && (
            <div className="flex">
              <span className="text-red-600">{errorMessage}</span>
            </div>
          )}
          <div className="space-y-4">
            <input
              type="text"
              placeholder={t('actions.enterToken')}
              value={token}
              onChange={(event) => setToken(event.target.value)}
              className="w-full px-4 py-2 rounded-md border-4 border-darkBlue focus:outline-none"
            />
          </div>
          <div className="justify-center">
            <span className="text-red-600 font-chillaxMedium text-xs md:text-base">
              <Trans i18nKey="warning.token" />
            </span>
          </div>
        </div>
        <div className="space-y-2">
          <div className="flex justify-start items-center space-x-4">
            <Button onClick={handleLoadWithUniques}>
              {t('actions.withUnique')}
            </Button>
            <Button onClick={loadWithoutUniques}>
              {t('actions.withoutUnique')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UniqueCardsModal;
